define("ember-svg-jar/inlined/icon.disabled-toilet", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<defs><style>.cls-1{fill:#fff}</style></defs><rect width=\"400\" height=\"400\" rx=\"94.02\" ry=\"94.02\"/><path class=\"cls-1\" d=\"M248.88 321.05c-2.02-6.05-8.56-9.33-14.61-7.3l-13.6 4.53-26.32-52.64a11.569 11.569 0 00-10.33-6.39h-73.15v-15.4h50.05c6.38 0 11.55-5.17 11.55-11.55s-5.17-11.55-11.55-11.55h-50.05v-13.81c11.28-4.58 19.25-15.65 19.25-28.55 0-16.98-13.82-30.8-30.8-30.8s-30.8 13.82-30.8 30.8c0 12.9 7.97 23.97 19.25 28.55v16.36c-21.68 5.87-39.74 21.49-48.51 43.05-15.19 37.37 2.85 80.12 40.22 95.31 8.99 3.65 18.3 5.39 27.45 5.39 28.9 0 56.33-17.23 67.86-45.61 2.4-5.91-.44-12.65-6.35-15.05-5.91-2.4-12.65.44-15.05 6.35-10.39 25.57-39.65 37.91-65.21 27.52-25.57-10.39-37.91-39.65-27.52-65.22 5.16-12.68 15.02-22.36 27.11-27.38v23.12c0 6.38 5.17 11.55 11.55 11.55h77.56l27.61 55.22a11.562 11.562 0 0013.98 5.8l23.1-7.7c6.05-2.02 9.32-8.56 7.3-14.61zM99.31 170.7c4.25 0 7.7 3.45 7.7 7.7s-3.45 7.7-7.7 7.7-7.7-3.45-7.7-7.7 3.45-7.7 7.7-7.7zM118.42 56.97h20.61l22.38 63.6 24.01-63.6h15l23.64 63.6 22.97-63.6h18.91L224.2 166.52l-31.77-79.41-32.28 79.41-41.74-109.55zM264.81 109.97c0-7.92 1.35-15.18 4.06-21.79 2.76-6.76 6.5-12.52 11.23-17.31 4.78-4.88 10.46-8.73 17.06-11.54 6.45-2.76 13.52-4.14 21.2-4.14 4.88 0 9.26.5 13.15 1.48 3.94.99 7.61 2.32 11.01 4.01 3.64 1.78 6.57 3.56 8.79 5.34 2.66 2.13 4.78 4.08 6.35 5.86L345.4 83.84c-2.66-2.95-6.21-5.61-10.64-7.97-4.63-2.46-10.05-3.69-16.25-3.69-5.17 0-9.97 1.01-14.41 3.03-4.14 1.92-7.83 4.67-11.08 8.27-3.1 3.49-5.52 7.5-7.24 12.03-1.72 4.57-2.59 9.4-2.59 14.46s.86 9.96 2.59 14.54c1.67 4.43 4.11 8.44 7.31 12.03 3.1 3.49 6.82 6.22 11.15 8.19 4.43 2.02 9.23 3.03 14.41 3.03 6.35 0 11.97-1.33 16.84-3.99 4.92-2.71 8.79-5.71 11.6-9l12.12 11.96c-1.87 2.22-4.21 4.43-7.02 6.65-2.71 2.12-5.79 4.04-9.23 5.76-3.3 1.63-7.07 2.98-11.3 4.06-4.14 1.03-8.52 1.55-13.15 1.55-7.63 0-14.75-1.4-21.35-4.21-6.65-2.85-12.34-6.67-17.06-11.44-4.68-4.77-8.42-10.56-11.23-17.35-2.71-6.6-4.06-13.85-4.06-21.77z\"/>",
    "attrs": {
      "id": "Layer_1",
      "data-name": "Layer 1",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 400 400"
    }
  };
  _exports.default = _default;
});