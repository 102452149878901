define("ember-svg-jar/inlined/pride/menu.home", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\" stroke=\"currentColor\"><path d=\"M13.911 13.28s6.831 0 6.831-6.39c0-4.548-3.05-6.456-6.83-6.388-3.835.07-6.384 1.697-6.508 5.109-.14 3.832 1.05 6.69 6.507 7.668z\"/><path d=\"M14.46 13.465s3.22 6.024 8.855 3.012c4.012-2.145 3.815-6.613 2.413-9.036-1.893-3.274-4.506-4.829-7.573-3.33-3.445 1.684-6.066 2.453-3.695 9.354z\"/><path d=\"M14.537 19.856s3.22 6.024 8.855 3.012c4.011-2.144 3.918-6.778 2.413-9.036-2.098-3.147-4.506-4.829-7.573-3.33-3.446 1.684-6.067 2.453-3.695 9.354z\"/><path d=\"M7.888 22.645s3.22 6.024 8.855 3.012c4.011-2.145 4.777-6.084 2.413-9.036-2.364-2.953-4.506-4.829-7.573-3.33-3.446 1.684-6.067 2.452-3.695 9.354z\"/><path d=\"M2.556 19.404s1.768 5.155 7.973 2.64c4.215-1.708 5.66-5.712 3.295-8.664-2.364-2.952-4.468-4.689-7.573-3.33-3.513 1.538-6.64 3.513-3.695 9.354z\"/><path d=\"M1.336 13.452s3.22 6.024 8.854 3.012c4.012-2.144 3.948-6.149 2.414-9.036-1.775-3.34-5.144-4.656-8.21-3.158C.947 5.954-.57 8.33 1.335 13.452z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 27.291 27.015"
    }
  };
  _exports.default = _default;
});