define("ember-svg-jar/inlined/freedom/app.touch-tour", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path class=\"st0\" d=\"M268.5 1.7c-27.3 3.2-43.5 6.7-65 13.8-44.9 14.9-82.1 37.9-115.6 71.4C40.3 134.5 11.4 194 3.4 261.1c-2.1 17.7-2.1 50.1 0 67.9 8 66.6 37.1 126.6 84.6 174 45 45 101.2 73.2 165.5 83.2 11.7 1.8 18.4 2.2 41 2.2 16.8.1 30.6-.4 36.5-1.2 68.6-9.3 124.9-36.9 172.6-84.6 54.3-54.4 83.8-122.3 86.1-198.6 3.1-103.8-46.6-198.9-133.8-256-37.4-24.5-81.1-40.2-126.5-45.5-11.7-1.4-51.8-1.9-60.9-.8zM308.8 66c20.1 1.4 27.2 2.6 44.7 7 32.2 8.2 62.4 23.4 86.5 43.6 41.8 35 66.3 74 78.9 125.4 8.2 33.6 8 74.2-.5 107.5-14.8 58.2-47 103.9-96.2 136.6-30.9 20.5-65 32.9-101.4 36.8-12.5 1.4-38.1 1.4-50.6 0-50-5.4-96.2-27.2-133.2-62.6-17.6-16.9-29.4-32.4-43.9-57.8C77.7 375.6 66 329.8 66 296.9c0-11.1 2.6-37.9 4.6-47.6 3.5-16.9 12.9-43.1 21.4-59.6 14-27.2 33-51.2 54.6-69 24.7-20.3 41.1-30.1 66-39.5 27.4-10.4 44.8-13.9 79.2-16.1 1.3 0 8.9.4 17 .9z\"/><path class=\"st0\" d=\"M265 89.6c-60.2 9.2-109.6 41.5-144.2 94.4-36.2 55.2-42.4 126.5-16.7 190.3 24.7 61.3 83.9 110.6 148.4 123.6 53.8 10.8 112 .1 155.5-28.6 41.7-27.5 72.8-68.7 86.6-114.8 9.9-32.8 10.8-73.8 2.5-109.7-8.2-35.2-26.8-68.2-53.4-95-34.6-34.7-74.5-54.3-122.8-60.3-15.3-1.9-43-1.8-55.9.1zM291 237v14h-43v115h-34V251h-44v-13.3c0-7.4.3-13.7.7-14 .3-.4 27.6-.7 60.5-.7H291v14zm133.8.2l.3 13.8h-18.3c-10.1 0-20 .3-22 .6l-3.8.7V367h-9.3c-5.2 0-12.8-.3-17-.6l-7.7-.7V251h-43v-28l60.3.2 60.2.3.3 13.7z\"/>",
    "attrs": {
      "viewBox": "0 0 600 600",
      "fill": "currentColor"
    }
  };
  _exports.default = _default;
});