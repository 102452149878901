define("ember-svg-jar/inlined/icons.bar-pub", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M747.56 4.04H325.85C145.89 4.04 0 149.93 0 329.9v421.71c0 179.96 145.89 325.85 325.85 325.85h421.71c179.96 0 325.85-145.89 325.85-325.85V329.9c0-179.97-145.89-325.85-325.85-325.85zm-2.03 420.61c-29.29 83.81-45.76 169.95-51.59 258.03-4.61 69.63-1.38 138.56 16.85 206.45 3.49 13 7.98 25.76 11.83 38.68 5.37 18.03-6.26 33.67-25.92 33.72-105.98.2-211.96.2-317.95.03-20.25-.04-33.23-16.16-26.73-34.28 15.4-42.91 25.45-87 27.87-131.96 2.38-44.14 2.3-88.62-.4-132.74-5.58-91.11-30.32-178.69-59.31-265.44-16.63-49.76-19.75-101.41-13.8-153.07 3.76-32.69 10.36-65.18 17.28-97.46 4.6-21.46 13.47-26.76 36.64-26.78 58.79-.02 117.59 0 176.38 0h174.06c26.8 0 35.52 5.98 41.67 30.05 23.56 92.15 24.95 183.72-6.89 274.79z\"/><path d=\"M688.9 171.83c-101.75.24-203.5.16-305.26.16h-10.22c-11.96 49.69-16.11 98.7-12.04 148.23 1.62 19.71 16.13 26.46 34.41 16.73 6.39-3.4 12.74-6.9 18.85-10.72 31.16-19.45 61.61-18.43 93.26.16 35.54 20.87 26.57 20.86 62.17-.03 31.66-18.58 62.06-19.45 93.29-.13 8.37 5.17 17.11 10.01 26.21 13.89 9.51 4.06 22.39-3.23 23.37-12.35 5.34-49.2 1.95-97.8-10.16-145.99-2.09-8.32-5.68-9.96-13.88-9.94z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "500",
      "height": "500",
      "viewBox": "0 0 1080 1080"
    }
  };
  _exports.default = _default;
});