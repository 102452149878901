define("ember-svg-jar/inlined/pride/icon.timetable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"none\"><path fill=\"#231F20\" d=\"M21.38 0h-1.041a1.188 1.188 0 00-1.189 1.188v17.984a1.189 1.189 0 001.189 1.189h1.04a1.19 1.19 0 001.19-1.189V1.188A1.19 1.19 0 0021.378 0zm.396 19.172a.396.396 0 01-.396.396h-1.041a.396.396 0 01-.396-.396V1.188c0-.218.177-.395.396-.395h1.04c.219 0 .396.177.396.395v17.984z\"/><path fill=\"#231F20\" d=\"M20.21 19.594l-.523.572-.012.013c-.043-.04-4.303-3.913-7.354-3.913H5.793a2.13 2.13 0 01-2.112-1.836 2.06 2.06 0 01-.022-.3V6.303a2.137 2.137 0 012.133-2.134h6.528c2.555 0 5.98-2.738 6.969-3.577l.013.016.5.588c-.182.155-.4.336-.653.535-1.507 1.194-4.145 3.015-6.433 3.212a4.856 4.856 0 01-.396.018H5.793c-.739 0-1.34.602-1.34 1.341v7.83c0 .74.601 1.342 1.34 1.342h6.528c.13 0 .263.006.396.018 2.314.199 4.986 2.061 6.433 3.21a20.08 20.08 0 011.06.893z\"/><path fill=\"#231F20\" d=\"M4.134 14.443h-.128A4.01 4.01 0 010 10.438v-.443A4.01 4.01 0 014.006 5.99v.792A3.216 3.216 0 00.793 9.995v.442a3.216 3.216 0 003.213 3.213h.128v.793z\"/><path d=\"M19.15 1.73V18.7c-1.447-1.148-4.12-3.01-6.433-3.21V4.943c2.288-.196 4.926-2.017 6.433-3.212z\"/><path fill=\"#8F89E0\" d=\"M11.924 4.96v10.513h-6.13c-.74 0-1.342-.602-1.342-1.342v-7.83c0-.738.602-1.341 1.341-1.341h6.13z\"/><path fill=\"#231F20\" d=\"M11.924 4.564h1V15.87h-1zm10.227 7.831v-.793a1.386 1.386 0 000-2.771v-.793a2.18 2.18 0 012.178 2.179 2.18 2.18 0 01-2.178 2.178zm2.751-2.575H27v1h-2.098zm-1.518-2.23l1.484-1.483.56.56-1.483 1.484zm0 5.253l.561-.56 1.483 1.483-.56.56zm-13.09 10.299H6.625c-.2 0-.37-.15-.393-.35L5.4 15.918l.787-.095.79 6.527h2.856l-.974-6.42.784-.119 1.043 6.876a.395.395 0 01-.392.456z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 27 23.142"
    }
  };
  _exports.default = _default;
});