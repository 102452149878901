define("ember-svg-jar/inlined/tommy-says.bg", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>tommy-says.bg</title><defs><linearGradient x1=\"20.128%\" y1=\".943%\" x2=\"80.458%\" y2=\"97.96%\" id=\"b\"><stop stop-color=\"#462286\" offset=\"0%\"/><stop stop-color=\"#32116C\" offset=\"100%\"/></linearGradient><path d=\"M140 8.75A8.75 8.75 0 00127.337.924L59.184 35H26.25C11.753 35 0 46.753 0 61.25 0 75.748 11.753 87.5 26.25 87.5h2.443L44.2 134.017A8.75 8.75 0 0052.5 140h8.75a8.75 8.75 0 008.75-8.75V92.908l57.337 28.668A8.75 8.75 0 00140 113.75v-105z\" id=\"a\"/></defs><g transform=\"scale(-1 1) rotate(-8 262.906 2740.522)\" fill=\"none\" fill-rule=\"evenodd\"><mask id=\"c\" fill=\"#fff\"><use xlink:href=\"#a\"/></mask><use fill=\"#000\" xlink:href=\"#a\"/><path fill=\"url(#b)\" mask=\"url(#c)\" d=\"M-12.788-43.837h160v205h-160z\"/></g>",
    "attrs": {
      "width": "380",
      "height": "205",
      "viewBox": "0 0 380 205",
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink"
    }
  };
  _exports.default = _default;
});