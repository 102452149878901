define("ember-svg-jar/inlined/icon.toilets", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M748.69 2.43H326.34C146.11 2.43 0 148.53 0 328.77v422.34c0 180.23 146.11 326.34 326.34 326.34h422.35c180.23 0 326.34-146.11 326.34-326.34V328.77c0-180.24-146.11-326.34-326.34-326.34zM470.41 767.76L367.39 511.1 247.3 768.94 73.06 318.61h100.07l85.36 250.77 65.93-165.41-34.15-85.36h84.77l97.13 240.77 73.58-240.77h91.83L470.4 767.76zm479.17-27.67c-23.94 9.02-50.04 13.54-78.29 13.54-45.13 0-84.38-9.81-117.73-29.43-33.36-19.63-58.87-46.01-76.53-79.18-17.66-33.16-26.49-69.55-26.49-109.19 0-43.57 8.83-82.41 26.49-116.56 17.66-34.14 42.39-60.73 74.17-79.77 31.79-19.03 68.48-28.55 110.08-28.55 26.29 0 50.63 3.53 73 10.6 22.37 7.06 41.99 15.89 58.87 26.49l-33.56 80.64c-31.01-23.55-62.79-35.32-95.37-35.32-21.98 0-42.59 6.09-61.81 18.25-19.23 12.18-34.54 28.75-45.92 49.75-11.39 20.99-17.07 44.25-17.07 69.76 0 27.87 5.3 52.3 15.9 73.29 10.6 21 25.7 37.39 45.33 49.16 19.62 11.77 42.58 17.66 68.88 17.66 21.19 0 40.22-3.44 57.1-10.3 16.87-6.86 30.22-15.02 40.03-24.43l35.32 76.53c-10.99 9.02-28.46 18.05-52.39 27.07z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "500",
      "height": "500",
      "viewBox": "0 0 1080 1080"
    }
  };
  _exports.default = _default;
});