define("ember-svg-jar/inlined/pride/menu.my-festival", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"none\"><path fill=\"#383838\" d=\"M19.168 17.265v-2.9h-.813v3.713h2.355v-.813h-1.542z\"/><path fill=\"#383838\" d=\"M19.168 17.265v-2.9h-.813v3.713h2.355v-.813h-1.542z\"/><path fill=\"#FFF\" d=\"M18.762 12.18a5.243 5.243 0 00-5.239 5.24 5.246 5.246 0 005.239 5.237A5.246 5.246 0 0024 17.42a5.243 5.243 0 00-5.238-5.238z\"/><path fill=\"#F366BA\" d=\"M18.762 21.844c-2.44 0-4.425-1.985-4.425-4.425s1.984-4.425 4.425-4.425 4.425 1.985 4.425 4.425-1.985 4.425-4.425 4.425z\"/><path fill=\"#FFF\" d=\"M19.168 14.365h-.813v3.713h2.355v-.813h-1.541v-2.9zM6.947 15.768v.813H9.44v-.813zm-4.153 0v.813h2.493v-.813zm4.153-3.835v.813H9.44v-.813zm4.152 0v.813h2.493v-.813zm-8.305 0v.813h2.493v-.813zm0-3.832v.814h2.493v-.814zm4.153 0v.814H9.44v-.814zm4.152 0v.814h2.493v-.814zm4.153 0v.814h2.493v-.814zM14.95 0v3.356h.814V0zM9.863 0v3.356h.813V0zM4.775 0v3.356h.813V0zm1.977.911v.813H8.7V.911zm5.087 0v.813h1.953V.911z\"/><path fill=\"#FFF\" d=\"M18.912.91h-1.985v.814h1.985c.447 0 .813.366.813.814v2.7H.813v-2.7c0-.448.366-.814.814-.814h1.985V.911H1.627C.732.91 0 1.643 0 2.538v15.247c0 .899.732 1.627 1.627 1.627H13.05a5.82 5.82 0 01-.223-.813H1.627a.813.813 0 01-.814-.814V6.051h18.912v5.393c.277.045.55.11.814.191V2.539c0-.895-.728-1.627-1.627-1.627z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "22.657"
    }
  };
  _exports.default = _default;
});