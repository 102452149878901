define("ember-svg-jar/inlined/icons/app/app.news", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<rect width=\"60.93\" height=\"12.8\" x=\"44.94\" y=\"40.16\" fill-opacity=\".5\" rx=\"2\"/><path d=\"M103.87 59.91H86.19a2 2 0 00-2 2v15a2 2 0 002 2h17.68a2 2 0 002-2v-15a2 2 0 00-2-2zm-2 15H88.19v-11h13.68zM29.77 42h-11a2 2 0 00-2 2v28.67c0 6.67 1.68 11.4 5 14.08A10.4 10.4 0 0028.38 89a8.87 8.87 0 001.82-.16 2 2 0 001.57-2V44a2 2 0 00-2-2zm-2 43a6.39 6.39 0 01-3.46-1.35C22 81.78 20.8 78 20.8 72.67V46h7zm47.92-25.09H47.52a2 2 0 000 4h28.17a2 2 0 000-4zM47.52 71.43H68.2a2 2 0 000-4H47.52a2 2 0 000 4zm28.17 3.51H47.52a2 2 0 000 4h28.17a2 2 0 000-4z\"/><path d=\"M112.91 30.8h-4.09a56.83 56.83 0 10.59 57.13 1.81 1.81 0 00.2-.48 11.8 11.8 0 005.29-9.54.71.71 0 000-.14v-45a2 2 0 00-1.99-1.97zM60 112.7a52.83 52.83 0 1144.1-81.9h-66a2 2 0 00-2 2v54.93a2 2 0 002 2h64a12.54 12.54 0 001.55-.11A53 53 0 0160 112.7zm50.91-35a7.82 7.82 0 01-4.43 6.93 7.44 7.44 0 01-.72.31H40.12V34.8h70.79z\"/>",
    "attrs": {
      "viewBox": "0 0 120 120",
      "fill": "currentColor"
    }
  };
  _exports.default = _default;
});