define("ember-svg-jar/inlined/icons/app/app.marker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M60 3a56.83 56.83 0 1056.83 56.83A56.89 56.89 0 0060 3zm-1.76 109.66a2 2 0 00.17-.54c.12-.75 2.79-18.71-17.48-48-9.64-13.88-7.72-24.32-4.42-30.62C41.57 23.85 52.39 18.64 60 18.64s18.44 5.21 23.49 14.86c3.3 6.3 5.22 16.74-4.42 30.65-20.27 29.26-17.6 47.22-17.48 48a2 2 0 00.17.54H60c-.59 0-1.18-.01-1.76-.03zm7.22-.24a2.16 2.16 0 00.08-1c0-.16-2.54-17.1 16.82-45C93.13 50.87 90.85 38.93 87 31.65c-5.85-11.17-18-17-27-17s-21.19 5.84-27 17c-3.81 7.28-6.09 19.22 4.68 34.78 19.19 27.69 16.86 44.79 16.82 45.05a2 2 0 00.09.94 52.83 52.83 0 1110.91 0z\"/><path fill-opacity=\".5\" d=\"M72.24 45.37A12.24 12.24 0 1060 57.61a12.25 12.25 0 0012.24-12.24z\"/>",
    "attrs": {
      "viewBox": "0 0 120 120",
      "fill": "currentColor"
    }
  };
  _exports.default = _default;
});