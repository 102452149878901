define("ember-svg-jar/inlined/icons/app/app.parking", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-opacity=\".5\" d=\"M60 4.09a56.83 56.83 0 1056.84 56.83A56.9 56.9 0 0060 4.09zm0 109.66a52.83 52.83 0 1152.84-52.83A52.89 52.89 0 0160 113.75z\"/><path d=\"M65.53 21H46.79c-6.52 0-9.57 2.87-9.57 9v64.63c0 3.74 2.34 6.25 5.82 6.25S49 98.31 49 94.63V70.76h16.53c15.58 0 25.65-9.74 25.65-24.82S80.87 21 65.53 21zm0 45.81H45v27.82c0 1-.33 2.25-1.92 2.25-.45 0-1.82 0-1.82-2.25V30c0-4.29 1.72-5 5.57-5h18.7c13.15 0 21.65 8.24 21.65 21s-8.3 20.76-21.65 20.76z\"/><path d=\"M65.25 28H45v35.8h15.33c16.09 0 23-5.34 23-17.86C83.28 34.69 76.54 28 65.25 28zm-4.92 31.8H49V32h16.25c9 0 14 5 14 14 .03 8.5-3.14 13.8-18.92 13.8z\"/>",
    "attrs": {
      "viewBox": "0 0 120 120",
      "fill": "currentColor"
    }
  };
  _exports.default = _default;
});