define("@cloudcreativity/ember-quill/components/quill", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/object/internals"], function (_exports, _component, _templateFactory, _component2, _tracking, _object, _internals) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{yield (hash
    characters = this.characters
    editor=(component "quill-editor"
      id=this.editorId
      onLength=this.setLength
      onWords=this.setWords
      _toolbar=(concat "#" this.toolbarId)
    )
    length=this.length
    toolbar=(component "quill/toolbar"
      id=this.toolbarId
    )
    words=this.words
  )}}
  
  */
  {
    "id": "d36VWI6e",
    "block": "[[[18,1,[[28,[37,1],null,[[\"characters\",\"editor\",\"length\",\"toolbar\",\"words\"],[[30,0,[\"characters\"]],[50,\"quill-editor\",0,null,[[\"id\",\"onLength\",\"onWords\",\"_toolbar\"],[[30,0,[\"editorId\"]],[30,0,[\"setLength\"]],[30,0,[\"setWords\"]],[28,[37,3],[\"#\",[30,0,[\"toolbarId\"]]],null]]]],[30,0,[\"length\"]],[50,\"quill/toolbar\",0,null,[[\"id\"],[[30,0,[\"toolbarId\"]]]]],[30,0,[\"words\"]]]]]]],[1,\"\\n\"]],[\"&default\"],false,[\"yield\",\"hash\",\"component\",\"concat\"]]",
    "moduleName": "@cloudcreativity/ember-quill/components/quill.hbs",
    "isStrictMode": false
  });

  let QuillComponent = (_class = class QuillComponent extends _component2.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "length", _descriptor, this);

      _initializerDefineProperty(this, "characters", _descriptor2, this);

      _initializerDefineProperty(this, "words", _descriptor3, this);
    }

    get id() {
      return (0, _internals.guidFor)(this);
    }

    get toolbarId() {
      return `${this.id}-toolbar`;
    }

    get editorId() {
      return `${this.id}-editor`;
    }

    setLength(length) {
      this.length = length;
      this.characters = length - 1;
    }

    setWords(words) {
      this.words = words;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "length", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "characters", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "words", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "setLength", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setLength"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setWords", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setWords"), _class.prototype)), _class);
  _exports.default = QuillComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, QuillComponent);
});