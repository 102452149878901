define("ember-svg-jar/inlined/icons/app/app.calendar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M88.26 45.68h-.5a2 2 0 000 4h.5a2 2 0 000-4zm-47.64 4h1.05a2 2 0 000-4h-1.05a2 2 0 000 4zm37.71 0h1a2 2 0 000-4h-1a2 2 0 000 4zm-8.33-4h-1a2 2 0 000 4h1a2 2 0 000-4zm-18.9 0h-1.05a2 2 0 000 4h1.05a2 2 0 000-4zm9.42 0h-1a2 2 0 000 4h1a2 2 0 000-4zm-28.28 0h-.5a2 2 0 000 4h.5a2 2 0 000-4zm56.02 9.89h-.5a2 2 0 000 4h.5a2 2 0 000-4zm-37.16 0h-1.05a2 2 0 000 4h1.05a2 2 0 000-4zm9.42 0h-1a2 2 0 000 4h1a2 2 0 000-4zm9.48 0h-1a2 2 0 000 4h1a2 2 0 000-4zm-29.38 4h1.05a2 2 0 000-4h-1.05a2 2 0 000 4zm-8.38-4h-.5a2 2 0 000 4h.5a2 2 0 000-4zm56.02 9.89h-.5a2 2 0 000 4h.5a2 2 0 000-4zm-27.74 0h-1a2 2 0 000 4h1a2 2 0 000-4zm9.48 0h-1a2 2 0 000 4h1a2 2 0 000-4zm9.38 0h-1a2 2 0 000 4h1a2 2 0 000-4zm-28.28 0h-1.05a2 2 0 000 4h1.05a2 2 0 100-4zm-18.86 0h-.5a2 2 0 100 4h.5a2 2 0 000-4zm18.32 9.9h-.5a2 2 0 000 4h.5a2 2 0 000-4zm-8.88 0h-1a2 2 0 000 4h1a2 2 0 000-4zm-9.44 0h-.5a2 2 0 100 4h.5a2 2 0 000-4z\" fill-opacity=\".5\"/><path d=\"M60 3a56.83 56.83 0 1056.83 56.83A56.89 56.89 0 0060 3zm0 109.7a52.83 52.83 0 1152.83-52.83A52.89 52.89 0 0160 112.7z\"/><path d=\"M97.4 30.8H83.13v-3.86a2 2 0 10-4 0v3.86H38.89v-3.86a2 2 0 10-4 0v3.86H22.6a2 2 0 00-2 2v54.14a2 2 0 002 2h74.8a2 2 0 002-2V32.8a2 2 0 00-2-2zM24.6 84.94V40.33h70.8v44.61z\"/><path d=\"M78.88 52.57a5 5 0 105 5 5 5 0 00-5-5z\"/><circle cx=\"41.15\" cy=\"67.46\" r=\"5\"/>",
    "attrs": {
      "viewBox": "0 0 120 120",
      "fill": "currentColor"
    }
  };
  _exports.default = _default;
});