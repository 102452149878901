define("ember-svg-jar/inlined/icons/app/app.information", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M60 4.09a56.83 56.83 0 1056.83 56.83A56.89 56.89 0 0060 4.09zm0 109.66a52.83 52.83 0 1152.83-52.83A52.88 52.88 0 0160 113.75z\"/><path fill-opacity=\".5\" d=\"M60 45.12c-3.76 0-6.7 2.45-6.7 5.54l-1.1 43.86a5.35 5.35 0 001.58 3.92 8.68 8.68 0 006.22 2.39c4.39 0 7.84-2.74 7.84-6.25l-1-43.88c-.03-3.13-3.04-5.58-6.84-5.58zm0 51.71a4.72 4.72 0 01-3.3-1.17 1.42 1.42 0 01-.47-1l1.03-43.96c0-.74 1.11-1.58 2.7-1.58s2.85.84 2.85 1.63l1 43.85c-.03 1.06-1.6 2.23-3.81 2.23zM60 21a9.16 9.16 0 109.16 9.15A9.2 9.2 0 0060 21zm0 14.31a5.16 5.16 0 115.16-5.16A5.2 5.2 0 0160 35.32z\"/>",
    "attrs": {
      "viewBox": "0 0 120 120",
      "fill": "currentColor"
    }
  };
  _exports.default = _default;
});