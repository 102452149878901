define("ember-svg-jar/inlined/icon.information-point", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M539.94 830.09c159.99 0 290.15-130.16 290.15-290.15S699.93 249.8 539.94 249.8 249.8 379.95 249.8 539.94s130.16 290.15 290.15 290.15zm-59.66-506.73c0-11.25 9.12-20.37 20.37-20.37h78.59c11.25 0 20.37 9.12 20.37 20.37v30.07c0 11.25-9.12 20.37-20.37 20.37h-78.59c-11.25 0-20.37-9.11-20.37-20.37v-30.07zm0 103.46c0-11.25 9.12-20.37 20.37-20.37h78.59c11.25 0 20.37 9.11 20.37 20.37v332.94c0 11.25-9.12 20.37-20.37 20.37h-78.59c-11.25 0-20.37-9.11-20.37-20.37V426.82z\"/><path d=\"M328.77 1077.46h422.35c180.23 0 326.34-146.11 326.34-326.34V328.77c0-180.23-146.11-326.34-326.34-326.34H328.77C148.54 2.43 2.43 148.54 2.43 328.77v422.35c0 180.23 146.11 326.34 326.34 326.34zm211.17-925.29c214.16 0 387.77 173.61 387.77 387.77S754.1 927.72 539.94 927.72 152.17 754.11 152.17 539.94s173.61-387.77 387.77-387.77z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 1080 1080"
    }
  };
  _exports.default = _default;
});