define("ember-can/computed", ["exports", "@ember/object", "@ember/application", "@ember/debug"], function (_exports, _object, _application, _debug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.ability = ability;

  function ability(abilityName, resourceName) {
    (true && !(false) && (0, _debug.deprecate)('Using ability() computed property is deprecated. Use getters and Can service directly.', false, {
      for: 'ember-can',
      since: {
        enabled: '4.0.0'
      },
      id: 'ember-can.deprecate-ability-computed',
      until: '5.0.0'
    }));
    resourceName = resourceName || abilityName;
    return (0, _object.computed)(resourceName, function () {
      let canService = (0, _application.getOwner)(this).lookup('service:abilities');
      return canService.abilityFor(abilityName, (0, _object.get)(this, resourceName));
    }).readOnly();
  }
});