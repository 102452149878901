define("ember-svg-jar/inlined/icons/app/app.amenities", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M60 3.67a56.83 56.83 0 1056.82 56.83A56.9 56.9 0 0060 3.67zm-8.55 109l3.88-23.32a2 2 0 00-1.41-2.25c-.12 0-11.46-3.51-13.5-15.24 3.12 1.35 8.56 2.8 17.68 3.09a1.72 1.72 0 00.39 0h3.03a1.82 1.82 0 00.52-.08c9-.3 14.45-1.74 17.56-3.09-2 11.66-13 15.09-13.51 15.25a2 2 0 00-1.41 2.24l3.89 23.34a52.69 52.69 0 01-17.12 0zm21-.8l-3.6-21.58c4.24-1.83 15-7.88 15-22.2a2 2 0 00-1.3-1.9 1.94 1.94 0 00-2.19.57s-4.1 4.05-21.01 4.24c-15.83-.33-19.73-4.16-19.73-4.16a2 2 0 00-3.54 1.28c0 14.31 10.78 20.37 15 22.19l-3.59 21.57a52.83 52.83 0 1125 0z\" class=\"cls-1\"/><path d=\"M79.22 58.93A47.54 47.54 0 0070 56.28c6.21-4.11 10.42-11.9 10.42-20.83 0-13.19-9.17-23.92-20.44-23.92S39.51 22.26 39.51 35.45c0 8.89 4.17 16.66 10.34 20.78a48.58 48.58 0 00-9.05 2.68 2 2 0 00-.12 3.66 48.7 48.7 0 0019.6 4 46.54 46.54 0 0019-4 2 2 0 000-3.67zM43.51 35.45c0-11 7.37-19.92 16.43-19.92s16.44 8.94 16.44 19.92c0 10.66-7 19.36-15.65 19.86h-1.69c-8.64-.57-15.53-9.25-15.53-19.86z\" class=\"cls-1\"/><circle cx=\"27.08\" cy=\"41.45\" r=\"4.75\" fill=\"#ea9da7\"/>",
    "attrs": {
      "data-name": "Layer 1",
      "viewBox": "0 0 120 120"
    }
  };
  _exports.default = _default;
});