define("ember-svg-jar/inlined/icons/app/app.exhibitions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"currentColor\"><path d=\"M60 3.17C28.614 3.17 3.17 28.614 3.17 60S28.614 116.83 60 116.83 116.83 91.386 116.83 60C116.797 28.627 91.373 3.203 60 3.17zm0 109.66C30.823 112.83 7.17 89.177 7.17 60S30.823 7.17 60 7.17 112.83 30.823 112.83 60c-.033 29.163-23.667 52.797-52.83 52.83z\"/><path d=\"M89.25 67.68h-58.5a2 2 0 01-2-2V29.41a2 2 0 012-2h58.5a2 2 0 012 2v36.27a2 2 0 01-2 2zm-56.5-4h54.5V31.41h-54.5v32.27z\"/><path d=\"M60 58.76a2 2 0 01-1.62-.82l-5.88-8-21.14 6.68a2.006 2.006 0 11-1.26-3.81l22.52-7.17a2 2 0 012.22.72l5.75 7.88L88.39 41a2 2 0 011.71 3.61l-29.25 14a2 2 0 01-.85.15z\"/><path fill-opacity=\".5\" d=\"M65.4 48.32a7.3 7.3 0 117.3-7.3 7.32 7.32 0 01-7.3 7.3zm0-10.6a3.3 3.3 0 100 6.6 3.3 3.3 0 000-6.6z\"/><path d=\"M30.75 96.16a2 2 0 01-2-2v-20.1a2 2 0 114 0v20.1a2 2 0 01-2 2z\"/><path fill-opacity=\".5\" d=\"M60.87 83a71.23 71.23 0 01-31.06-7.17 2 2 0 111.88-3.53c.28.15 28.44 14.77 56.63 0a2.004 2.004 0 011.86 3.55A62.6 62.6 0 0160.87 83z\"/><path d=\"M89.25 96.16a2 2 0 01-2-2v-20.1a2 2 0 114 0v20.1a2 2 0 01-2 2z\"/></g>",
    "attrs": {
      "viewBox": "0 0 120 120"
    }
  };
  _exports.default = _default;
});