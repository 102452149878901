define("ember-svg-jar/inlined/icons/app/app.settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M60 4.09a56.83 56.83 0 1056.83 56.83A56.89 56.89 0 0060 4.09zm0 109.66a52.83 52.83 0 1152.83-52.83A52.88 52.88 0 0160 113.75z\"/><path fill-opacity=\".5\" d=\"M65 96H53V85h12zm10.118-39.875l-4.725 4.876C66.612 64.87 64.25 68.05 64.25 76h-10.5v-2.65c0-5.83 2.362-11.13 6.142-14.999l6.51-6.678c1.943-1.908 3.098-4.558 3.098-7.473 0-5.83-4.725-10.6-10.5-10.6s-10.5 4.77-10.5 10.6H38C38 32.487 47.398 23 59 23c11.603 0 21 9.487 21 21.2 0 4.664-1.89 8.904-4.882 11.925z\"/>",
    "attrs": {
      "viewBox": "0 0 120 120",
      "fill": "currentColor"
    }
  };
  _exports.default = _default;
});