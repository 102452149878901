define("ember-svg-jar/inlined/icons/app/app.programme", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-opacity=\".5\" d=\"M21.56 18.84a.72.72 0 000 1.44h.72v-1.44h-.72z\"/><path d=\"M17.84 12.058l-1.035-.152a.719.719 0 01-.542-.393l-.463-.938-.463.938a.719.719 0 01-.542.393l-1.035.152.749.73a.72.72 0 01.207.636l-.177 1.032.926-.487a.713.713 0 01.67 0l.926.487-.177-1.032a.72.72 0 01.207-.637l.75-.73z\"/><path fill-opacity=\".5\" d=\"M18.68 18.84h.853a2.157 2.157 0 012.027-1.44h.72V7.32H9.32v20.256h12.96V21.72h-.72c-.937 0-1.73-.604-2.027-1.44h-.853a.72.72 0 110-1.44zm-.998-2.431L15.8 15.42l-1.882.989a.72.72 0 01-1.044-.759l.359-2.095-1.523-1.485a.72.72 0 01.4-1.227l2.103-.307.941-1.906c.242-.492 1.05-.492 1.291 0l.942 1.906 2.104.307a.72.72 0 01.4 1.227l-1.524 1.485.36 2.095a.72.72 0 01-1.045.759z\"/><path fill-opacity=\".5\" d=\"M24.44 3H7.16C5.969 3 5 3.969 5 5.16v21.696c0 .398.322.72.72.72h2.16V6.6a.72.72 0 01.72-.72H23a.72.72 0 01.72.72v20.976h2.16a.72.72 0 00.72-.72V5.16c0-1.191-.969-2.16-2.16-2.16z\"/>",
    "attrs": {
      "viewBox": "0 0 32 32",
      "fill": "currentColor"
    }
  };
  _exports.default = _default;
});