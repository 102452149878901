define("ember-svg-jar/inlined/sesh/app.home", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M540 1076.28c-20.24 0-38.1-13.23-44-32.59l-55.93-183.53c-88.36-21.02-168.51-69.87-228.08-139.55-32.68-38.23-58.19-81.38-75.83-128.25-18.26-48.53-27.52-99.64-27.52-151.91 0-58.21 11.41-114.71 33.92-167.92 21.73-51.37 52.82-97.5 92.43-137.1 39.6-39.6 85.73-70.7 137.1-92.43C425.3 20.49 481.8 9.08 540.01 9.08S654.72 20.49 707.93 43c51.37 21.73 97.5 52.82 137.1 92.43 39.6 39.6 70.7 85.73 92.43 137.1 22.51 53.21 33.92 109.71 33.92 167.92 0 52.27-9.26 103.38-27.52 151.91-17.64 46.87-43.15 90.02-75.83 128.25-59.56 69.68-139.72 118.53-228.08 139.55l-55.93 183.53c-5.9 19.36-23.76 32.59-44 32.59zm0-975.19c-90.65 0-175.87 35.3-239.97 99.4-64.1 64.1-99.4 149.32-99.4 239.97 0 41.17 7.28 81.37 21.63 119.51 13.87 36.85 33.94 70.79 59.66 100.87 51.86 60.67 123.51 101.27 201.73 114.33a46.008 46.008 0 0136.43 31.96L540 872.49l19.92-65.36a45.976 45.976 0 0136.43-31.96c78.23-13.06 149.87-53.66 201.73-114.33 25.72-30.08 45.79-64.02 59.66-100.87 14.35-38.13 21.63-78.34 21.63-119.51 0-90.65-35.3-175.87-99.4-239.97-64.1-64.1-149.32-99.4-239.97-99.4z\" fill=\"currentColor\"/><path d=\"M540 660c-59.01 0-114.5-22.98-156.23-64.71-41.73-41.73-64.71-97.21-64.71-156.23s22.98-114.5 64.71-156.23c41.73-41.73 97.21-64.71 156.23-64.71s114.5 22.98 156.23 64.71 64.71 97.21 64.71 156.23-22.98 114.5-64.71 156.23C654.5 637.02 599.02 660 540 660zm0-349.87c-71.1 0-128.94 57.84-128.94 128.94S468.9 568.01 540 568.01s128.94-57.84 128.94-128.94S611.1 310.13 540 310.13z\" fill=\"currentColor\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "data-name": "Layer 1",
      "viewBox": "0 0 1080 1080"
    }
  };
  _exports.default = _default;
});