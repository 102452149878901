define("ember-svg-jar/inlined/icons/app/app.streaming", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M60 3.09a56.83 56.83 0 1056.83 56.83A56.89 56.89 0 0060 3.09zm0 109.66a52.83 52.83 0 1152.83-52.83A52.89 52.89 0 0160 112.75z\"/><path d=\"M98.36 49.59l-36.52-25a2 2 0 00-2.23 0l-38 25a2 2 0 102.2 3.34l36.88-24.24 35.41 24.2a2 2 0 102.26-3.3z\"/><path d=\"M91.11 53a2 2 0 00-2 2v30.5H71.72V65.16a.91.91 0 000-.17c-.33-3.85-3.11-11.11-11.27-11.11s-11.08 7.39-11.5 11.3a1.48 1.48 0 000 .21v20.15H30.84V55a2 2 0 00-4 0v32.5a2 2 0 002 2h22.09a2 2 0 002-2v-22c.12-.93 1.27-7.64 7.51-7.64.67 0 6.57.2 7.28 7.37v22.31a2 2 0 002 2h21.39a2 2 0 002-2V55a2 2 0 00-2-2z\"/><circle cx=\"172.19\" cy=\"31.05\" r=\"5.67\" fill-opacity=\".5\"/>",
    "attrs": {
      "fill": "currentColor",
      "viewBox": "0 0 120 120"
    }
  };
  _exports.default = _default;
});