define("ember-svg-jar/inlined/sesh/app.my-sesh", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<circle cx=\"540.53\" cy=\"801.35\" r=\"220.88\" fill=\"currentColor\" fill-opacity=\".3\"/><path d=\"M1034.09 97.37h-145.6V45.73c0-24.85-20.15-45-45-45s-45 20.15-45 45v51.64H585.53V45.73c0-24.85-20.15-45-45-45s-45 20.15-45 45v51.64H281.71V45.73c0-24.85-20.15-45-45-45s-45 20.15-45 45v51.64H46.96c-24.85 0-45 20.15-45 45v754.52c0 24.85 20.15 45 45 45h310.21c-20.08-26.15-34.68-56.72-42.11-90H91.96V402.73h897.13v449.16h-223.1c-7.43 33.28-22.03 63.85-42.11 90h310.21c24.85 0 45-20.15 45-45V142.37c0-24.85-20.15-45-45-45zm-45 215.36H91.96V187.37h99.75v46.45c0 24.85 20.15 45 45 45s45-20.15 45-45v-46.45h213.82v46.45c0 24.85 20.15 45 45 45s45-20.15 45-45v-46.45h212.96v46.45c0 24.85 20.15 45 45 45s45-20.15 45-45v-46.45h100.6v125.36z\" fill=\"currentColor\"/><path d=\"M540.53 1077.38c-73.73 0-143.04-28.71-195.18-80.84-52.13-52.13-80.84-121.45-80.84-195.18s28.71-143.04 80.84-195.18c52.13-52.13 121.45-80.84 195.18-80.84s143.04 28.71 195.18 80.84c52.13 52.13 80.85 121.45 80.85 195.18s-28.71 143.04-80.85 195.18c-52.13 52.13-121.45 80.84-195.18 80.84zm0-462.04c-102.57 0-186.02 83.45-186.02 186.02s83.45 186.02 186.02 186.02c102.57 0 186.02-83.45 186.02-186.02S643.1 615.34 540.53 615.34z\" fill=\"currentColor\"/><path d=\"M440.61 815.72l58.83 58.83 129.82-129.82\" fill=\"currentColor\" fill-opacity=\".3\"/><path d=\"M499.44 919.55c-11.94 0-23.38-4.74-31.82-13.18l-58.83-58.83c-17.57-17.57-17.57-46.07 0-63.64s46.07-17.57 63.64 0l27.01 27.01 98-98c17.57-17.57 46.07-17.57 63.64 0s17.57 46.07 0 63.64L531.26 906.37a45.006 45.006 0 01-31.82 13.18z\" fill=\"currentColor\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "data-name": "Layer 1",
      "viewBox": "0 0 1080 1080"
    }
  };
  _exports.default = _default;
});