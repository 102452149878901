define("ember-svg-jar/inlined/icons/app/app.music", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<circle cx=\"62.56\" cy=\"91.22\" r=\"22.05\" fill-opacity=\".5\"/><path d=\"M60 3.17A56.83 56.83 0 10116.83 60 56.89 56.89 0 0060 3.17zm0 4A52.84 52.84 0 01109.66 78a1.91 1.91 0 00-.48-.06h-.57v-7.82h.57a2 2 0 000-4H86.45v-8.75a19 19 0 002.23.15c5.43 0 9.87-2.4 11.15-6.49.88-2.82.1-5.9-2.21-8.69a17.89 17.89 0 00-6.22-4.66L92.78 35a2 2 0 00-3.55-1.84l-1.63 3.17c-7.6-1.95-14.6.48-16.27 5.81-.88 2.82-.09 5.9 2.21 8.69a18.94 18.94 0 008.91 5.7v9.59h-.54a2 2 0 000 4h.54v6.45a24.81 24.81 0 00-18.21-10.25V44.6l.46-.23c6.24-3.27 9.19-9.51 6.73-14.22-2.28-4.34-8.27-5.62-14.08-3.22l-1.58-2.33a2 2 0 00-3.32 2.24l1.39 2a14.1 14.1 0 00-4.38 5 8.49 8.49 0 00-.28 8 8.52 8.52 0 006.7 4.3 13 13 0 001.66.1 14.61 14.61 0 002.7-.26v7.58h-5.88v-1.51a2 2 0 00-.25-4H35.6a2 2 0 000 4h.4v7.86h-.4a2 2 0 000 4h18.51a2 2 0 002-2 2 2 0 00-1.75-2v-2.37h5.88v8.79a24.84 24.84 0 00-18.73 11.23v-7.44h.58a2 2 0 000-4H14.82a2 2 0 100 4h.54V87h-.75A52.8 52.8 0 0160 7.17zm33.56 93.6a52.62 52.62 0 01-14.36 8.43A24.71 24.71 0 0085.4 82h8.15v18.65c0 .03.01.07.01.12zm-56-30.65V87h-18.2V70.12zM56.08 32.2l2.05 3a2 2 0 001.65.87 2 2 0 001.13-.34 2 2 0 00.53-2.73l-1.78-2.62a11.08 11.08 0 013.44-.6c2.18 0 4 .77 4.78 2.26 1.38 2.63-.93 6.67-5 8.82a11 11 0 01-6.44 1.32A4.79 4.79 0 0152.72 40a4.78 4.78 0 01.3-4.28 10.14 10.14 0 013.06-3.52zm6.24 38a20.81 20.81 0 11-20.81 20.85 20.83 20.83 0 0120.81-20.81zM37.51 91v.07a24.78 24.78 0 0010.81 20.46 52.18 52.18 0 01-18.16-8V91zm48.94-13v-7.88h18.16V78zm-2.36-30.55a2 2 0 00.92.22 2 2 0 001.77-1.08l2.78-5.35a13.93 13.93 0 015 3.65c.82 1 2.1 3 1.47 5-1 3.32-6.69 4.64-12.34 2.88a14.92 14.92 0 01-7-4.44c-.81-1-2.09-3-1.47-5 .72-2.32 3.7-3.66 7.35-3.66a16.41 16.41 0 013.2.33l-2.47 4.75a2 2 0 00.79 2.7zm-33.73 4.62v7.86H40v-7.86zM17.24 91h8.92v9.55A53.15 53.15 0 0117.24 91zm80.31 6.14V82H108a52.91 52.91 0 01-10.45 15.12z\"/>",
    "attrs": {
      "viewBox": "0 0 120 120"
    }
  };
  _exports.default = _default;
});