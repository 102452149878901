define("ember-svg-jar/inlined/icons/app/app.map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-opacity=\".4\" d=\"M46.3 846.45c-5.8 0-11.64-1.12-17.21-3.43a44.993 44.993 0 01-27.78-41.57V46.57c0-24.86 20.15-45 45-45h754.88c18.2 0 34.61 10.96 41.57 27.78 6.96 16.82 3.12 36.17-9.75 49.04L78.13 833.27a44.99 44.99 0 01-31.83 13.18zM91.31 91.57v601.24L692.55 91.57H91.31z\"/><path d=\"M558.73 1078.02H46.96c-24.85 0-45-20.15-45-45v-13.88c0-11.93 4.74-23.38 13.18-31.82l248.95-248.95c17.57-17.57 46.07-17.57 63.64 0l262.82 262.82c12.87 12.87 16.72 32.23 9.75 49.04a44.993 44.993 0 01-41.57 27.78zm-511.77-58.88zm94.76-31.12h308.37L295.91 833.84 141.73 988.02z\"/><path fill-opacity=\".4\" d=\"M1032.98 1078.02H768.46c-11.93 0-23.38-4.74-31.82-13.18l-371.1-371.1c-17.57-17.57-17.57-46.07 0-63.64L980.79 14.84a45.006 45.006 0 0131.82-13.18h20.37c24.85 0 45 20.15 45 45v986.36c0 24.85-20.15 45-45 45zm-245.88-90h200.88V134.93L461 661.91l326.1 326.1z\"/>",
    "attrs": {
      "viewBox": "0 0 1080 1080",
      "fill": "currentColor"
    }
  };
  _exports.default = _default;
});