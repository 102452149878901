define("@cloudcreativity/ember-quill/components/quill/toolbar/select", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component"], function (_exports, _component, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <select class={{this.qlClass}} ...attributes>
    {{#if (has-block)}}
      {{yield}}
    {{else}}
      {{#each @values as |value|}}
        <option selected={{eq @selected value}} value={{if value value}}></option>
      {{/each}}
    {{/if}}
  </select>
  
  */
  {
    "id": "D9KpNZRE",
    "block": "[[[11,\"select\"],[16,0,[30,0,[\"qlClass\"]]],[17,1],[12],[1,\"\\n\"],[41,[48,[30,5]],[[[1,\"    \"],[18,5,null],[1,\"\\n\"]],[]],[[[42,[28,[37,4],[[28,[37,4],[[30,2]],null]],null],null,[[[1,\"      \"],[10,\"option\"],[15,\"selected\",[28,[37,5],[[30,4],[30,3]],null]],[15,2,[52,[30,3],[30,3]]],[12],[13],[1,\"\\n\"]],[3]],null]],[]]],[13],[1,\"\\n\"]],[\"&attrs\",\"@values\",\"value\",\"@selected\",\"&default\"],false,[\"if\",\"has-block\",\"yield\",\"each\",\"-track-array\",\"eq\"]]",
    "moduleName": "@cloudcreativity/ember-quill/components/quill/toolbar/select.hbs",
    "isStrictMode": false
  });

  class QuillToolbarSelectComponent extends _component2.default {
    get qlClass() {
      if (this.args.type) {
        return `ql-${this.args.type}`;
      }

      return null;
    }

  }

  _exports.default = QuillToolbarSelectComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, QuillToolbarSelectComponent);
});