define("ember-svg-jar/inlined/icons/app/app.access", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M101.24 21.89l.34-.52a6.35 6.35 0 00-1.66-8.6 6.07 6.07 0 00-3.51-1.11 6.11 6.11 0 00-4.67 2.17 57 57 0 109.5 8.06zm-4.83-6.23a2.13 2.13 0 011.22.39 2.32 2.32 0 01.6 3.14L51.1 92a2.15 2.15 0 01-3.63 0L28.05 62a2.32 2.32 0 01.6-3.14 2.1 2.1 0 011.21-.39 2.16 2.16 0 011.82 1l17.61 27.2 45.31-70a2.15 2.15 0 011.81-1.01zM60 113.75a52.83 52.83 0 1129.53-96.6L49.29 79.29 35 57.28a6.17 6.17 0 00-5.18-2.83 6 6 0 00-3.5 1.11 6.35 6.35 0 00-1.66 8.6l19.42 30a6.14 6.14 0 0010.34 0L99 25.35a52.78 52.78 0 01-39 88.4z\"/>",
    "attrs": {
      "viewBox": "0 0 120 120",
      "fill": "currentColor"
    }
  };
  _exports.default = _default;
});