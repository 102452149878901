define("ember-svg-jar/inlined/icons/app/app.easy-read", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M900.9 687.9H657.5c-22.5 0-33.7-27.2-17.8-43l34.5-34.5c4.7-4.7 11.1-7.3 17.8-7.3h209c13.9 0 25.2 11.3 25.2 25.2v34.5c-.1 13.8-11.4 25.1-25.3 25.1m25.2 90.8c0 13.9-11.3 25.2-25.2 25.2H541.4c-22.4 0-33.7-27.1-17.8-43l34.4-34.5c4.7-4.7 11.1-7.4 17.8-7.4h325c13.9 0 25.2 11.3 25.2 25.3v34.4zm-611.6 80L194.7 738.8c-9.8-9.9-9.8-25.8 0-35.6l24.4-24.4c9.9-9.9 25.8-9.9 35.7 0l59.8 59.9c9.9 9.8 25.8 9.8 35.6 0l475.1-475.2c9.9-9.8 25.8-9.8 35.7 0l24.4 24.4c9.8 9.9 9.8 25.8 0 35.7L350.2 858.7c-9.8 9.8-25.8 9.8-35.7 0M410.3 603l-8.7 8.6V603h8.7zm-203.5-91.5c0-13.9 11.3-25.2 25.3-25.2h234c22.4 0 33.7 27.1 17.8 43l-34.4 34.4c-4.8 4.7-11.1 7.4-17.8 7.4H232.1c-13.9 0-25.3-11.3-25.3-25.2v-34.4zm0-116.1c0-13.9 11.3-25.2 25.3-25.2h350.1c22.5 0 33.7 27.2 17.8 43l-34.5 34.5c-4.7 4.7-11.1 7.3-17.8 7.3H232.1c-13.9 0-25.3-11.3-25.3-25.2v-34.4zm0-116.1c0-13.9 11.3-25.2 25.3-25.2h525.2v1.8l-75.7 75.7c-4.8 4.7-11.1 7.4-17.8 7.4H232.1c-13.9 0-25.3-11.3-25.3-25.2v-34.5zM540 0C241.8 0 0 241.8 0 540s241.8 540 540 540 540-241.7 540-540S838.2 0 540 0\"/>",
    "attrs": {
      "viewBox": "0 0 1080 1080",
      "fill": "currentColor"
    }
  };
  _exports.default = _default;
});