define("ember-svg-jar/inlined/icons/app/app.transport", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M59.83 3.67a56.83 56.83 0 1056.83 56.83A56.89 56.89 0 0059.83 3.67zm0 109.66a52.83 52.83 0 1152.83-52.83 52.88 52.88 0 01-52.83 52.83z\" class=\"cls-1\"/><path d=\"M90.13 25.31a6.4 6.4 0 00-4.74-1.9H34.47a6.43 6.43 0 00-4.75 1.9 9 9 0 00-2.11 6.06v53.05c0 4.1 3.8 6.77 5.83 7.9v7.18a2 2 0 002 2h11a2 2 0 002-2v-6.41h22.95v6.41a2 2 0 002 2h11a2 2 0 002-2v-7.18c2-1.13 5.82-3.8 5.82-7.9v-53a9.07 9.07 0 00-2.08-6.11zm-1.89 59.11c0 2.33-3.41 4.32-4.6 4.83a2 2 0 00-1.22 1.84v6.41h-7v-6.41a2 2 0 00-2-2H46.46a2 2 0 00-2 2v6.41h-7v-6.41a2 2 0 00-1.22-1.84c-1.2-.51-4.61-2.5-4.61-4.83V31.28a5.16 5.16 0 011-3.2 2.35 2.35 0 011.86-.67h50.9a2.33 2.33 0 011.84.66 5.13 5.13 0 011 3.3z\" class=\"cls-1\"/><path d=\"M82.94 64.72a1.77 1.77 0 00.08-.56V35a2 2 0 00-2-2H38.83a2 2 0 00-2 2v29.25a1.9 1.9 0 00.17.81c1.5 3.4 5 11.39 23.71 11.39s22.1-11.25 22.23-11.73zM58.41 72.4v-7c0-6.11-6.92-7.59-6.92-7.59l-1.92-.35a6.53 6.53 0 001.61-5.13c-.22-2.32-2.32-4.73-5.85-4.73a5.22 5.22 0 00-4.5 2.4V37H79v2.35a7.15 7.15 0 00-2.65-.49 7.07 7.07 0 00-7.3 6.14 8.35 8.35 0 00.37 3.48c-2.82.9-7 3.53-7 8.63v15.35H60.7c-.79-.01-1.56-.03-2.29-.06zm8-.4V57.06c0-3.86 4.55-4.92 4.67-5l1.75-.32A2 2 0 0074 48.51a4 4 0 01-1-3.18 3.05 3.05 0 013.34-2.51A2.82 2.82 0 0179 44.44v19.38C78.55 65 75.92 70.41 66.43 72z\" class=\"cls-1\"/><path fill=\"#a9dcf2\" d=\"M41 75.73a5.75 5.75 0 105.79 5.75A5.75 5.75 0 0041 75.73zm0 7.5a1.75 1.75 0 111.79-1.75A1.78 1.78 0 0141 83.23zm37.83-7.5a5.75 5.75 0 105.79 5.75 5.75 5.75 0 00-5.79-5.75zm0 7.5a1.75 1.75 0 111.79-1.75 1.78 1.78 0 01-1.79 1.75zM23.08 33a2 2 0 00-2 2v22.13a2 2 0 104 0V35a2 2 0 00-2-2zM97 33a2 2 0 00-2 2v22.13a2 2 0 004 0V35a2 2 0 00-2-2z\"/>",
    "attrs": {
      "data-name": "Layer 1",
      "viewBox": "0 0 120 120"
    }
  };
  _exports.default = _default;
});