define("ember-svg-jar/inlined/icons/app/app.speech-free", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path class=\"st0\" d=\"M297.2 355.1c-45.4 0-82.4 37-82.4 82.4v152.2c0 45.5 37 82.5 82.4 82.5h92L535 818.1c2.8 2.8 7.2 2.8 9.9 0l99.9-99.9-337-363.1h-10.6zm485.6 0H464.3l294.4 317.1h24.2c45.5 0 82.5-37 82.5-82.5V437.5c-.1-45.4-37.1-82.4-82.6-82.4\"/><path class=\"st0\" d=\"M852.9 773.8l12.1 13c21.6 23.2 20.2 59.5-3 81-11.1 10.2-25.1 15.3-39 15.3-15.4 0-30.7-6.2-42.1-18.4l-58-62.5-96.9 97c-22.9 22.9-53.5 35.6-86 35.6s-63.1-12.7-86-35.6L341.7 786.8h-44.5c-108.7 0-197.1-88.4-197.1-197.1V437.6c0-80.2 48.3-149.3 117.2-180.1l-20.6-22.1c-21.5-23.3-20.2-59.5 3-81.1 23.2-21.5 59.5-20.2 81 3l77.1 83.1h424.9c108.7 0 197.1 88.4 197.1 197.1v152.2c.1 84.1-52.7 155.7-126.9 184.1M540 0C241.8 0 0 241.8 0 540s241.8 540 540 540 540-241.7 540-540S838.2 0 540 0\"/>",
    "attrs": {
      "viewBox": "0 0 1080 1080",
      "fill": "currentColor"
    }
  };
  _exports.default = _default;
});