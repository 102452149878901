define("ember-svg-jar/inlined/icons/app/app.performance", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<defs><path id=\"a\" d=\"M0 0h113.661v113.661H0z\"/></defs><g fill=\"none\" fill-rule=\"evenodd\" transform=\"translate(3 3)\"><mask id=\"b\" fill=\"#fff\"><use xlink:href=\"#a\"/></mask><path fill=\"currentColor\" d=\"M56.831 109.661C27.7 109.661 4 85.962 4 56.83 4 27.7 27.7 4 56.831 4c29.131 0 52.83 23.7 52.83 52.83 0 29.132-23.699 52.831-52.83 52.831M56.831 0C25.494 0 0 25.494 0 56.83c0 31.337 25.494 56.831 56.831 56.831 31.336 0 56.83-25.494 56.83-56.831C113.661 25.494 88.167 0 56.831 0\" mask=\"url(#b)\"/><path fill=\"currentColor\" d=\"M25.436 59.898l21.026 13.41 21.193-13.515V23.925h-42.22v35.973zM46.462 77.68a2 2 0 01-1.075-.313L22.36 62.681a2 2 0 01-.925-1.686v-39.07a2 2 0 012-2h46.219a2 2 0 012 2v39.07a2 2 0 01-1.472 1.93L47.538 77.366a2 2 0 01-1.076.313z\"/><path fill=\"currentColor\" fill-opacity=\".5\" d=\"M46.795 59.338l-.116-.001c-6.005-.06-10.275-4.917-10.454-5.124a2 2 0 013.022-2.621c.057.064 3.325 3.718 7.497 3.746 2.479.059 4.853-1.252 7.123-3.772a2.001 2.001 0 012.972 2.677c-3.046 3.38-6.425 5.095-10.044 5.095m30.733 23.646a1.994 1.994 0 01-1.51-.687c-.057-.065-3.325-3.718-7.497-3.747h-.047c-2.44 0-4.821 1.27-7.076 3.773a2 2 0 01-2.972-2.678c3.046-3.38 6.425-5.095 10.044-5.095l.116.001c6.004.06 10.275 4.917 10.454 5.124a2.002 2.002 0 01-1.512 3.31\"/><path fill=\"currentColor\" d=\"M68.636 102.518c-.374 0-.747-.104-1.075-.313L44.534 87.52a2 2 0 01-.924-1.687V75.136a2 2 0 014 0v9.6l21.03 13.412 21.19-13.416V48.763H69.654a2 2 0 110-4H91.83a2 2 0 012 2v39.07c0 .685-.35 1.323-.929 1.69l-23.194 14.684a1.998 1.998 0 01-1.07.311\"/></g>",
    "attrs": {
      "xmlns:xlink": "http://www.w3.org/1999/xlink",
      "viewBox": "0 0 120 120"
    }
  };
  _exports.default = _default;
});