define("ember-svg-jar/inlined/icons/app/app.talks", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M110.9 35a26.32 26.32 0 00.18-3A25 25 0 0086.16 7.08h-5.58A56.76 56.76 0 10110.9 35zm-3.82-3a21 21 0 01-20.92 21H65.43a20.78 20.78 0 01-12-3.76 2 2 0 00-2.59.26l-6.38 6.71V32a21 21 0 0120.97-20.92h20.73A21 21 0 01107.08 32zm-47.23 80.83a52.83 52.83 0 010-105.66c.92 0 1.83 0 2.74.07A25 25 0 0040.51 32v29.18A2 2 0 0041.77 63a2.11 2.11 0 00.74.14 2 2 0 001.49-.58l8.68-9.14A24.76 24.76 0 0065.43 57h20.73a25 25 0 0023.11-15.63 52.83 52.83 0 01-49.42 71.5z\"/><path fill-opacity=\".5\" d=\"M50.82 66.75h-15a16.63 16.63 0 00-16.65 16.62A16.64 16.64 0 0035.8 100h15a16.54 16.54 0 009.5-3l7.13 7.49V83.37a16.63 16.63 0 00-16.61-16.62zm-1.32 22.4H30.56a2 2 0 010-4H49.5a2 2 0 010 4zm6.55-9H30.56a2 2 0 010-4h25.49a2 2 0 110 4z\"/><path d=\"M57.26 31.92a2 2 0 002 2h37.07a2 2 0 000-4H59.26a2 2 0 00-2 2zm28.28 6.42H59.26a2 2 0 100 4h26.28a2 2 0 000-4zM57.26 23.5a2 2 0 002 2h33.26a2 2 0 000-4H59.26a2 2 0 00-2 2z\"/>",
    "attrs": {
      "viewBox": "0 0 120 120",
      "fill": "currentColor"
    }
  };
  _exports.default = _default;
});