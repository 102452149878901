define("ember-svg-jar/inlined/icons/app/app.baby-change", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M60 3.67a56.83 56.83 0 1056.82 56.83A56.89 56.89 0 0060 3.67zm0 109.66a52.83 52.83 0 1152.82-52.83A52.88 52.88 0 0160 113.33z\" class=\"cls-1\"/><path d=\"M83.28 80.67l-8.17-8.17a2 2 0 00-2.83 0l-9.92 9.93a2 2 0 00-.59 1.44 2 2 0 00.62 1.43L69 91.56l-5.2 5.2a1.64 1.64 0 00-.21.25c-1.41 2-3.18 6.59.21 10a6.19 6.19 0 004.5 2 8 8 0 004.89-1.86l.11-.11 10.06-10c2.77-2.78 4.09-5.72 3.9-8.76a11.33 11.33 0 00-3.98-7.61zM80.52 94.1l-10 10c-.59.47-2.36 1.64-3.92.08s-.23-4.15.13-4.71l6.52-6.51a2 2 0 000-2.87l-6.6-6.26 7.07-7.06 6.82 6.82.18.16a7.38 7.38 0 012.56 4.71c.08 1.82-.84 3.72-2.76 5.64zM58.2 83.87a2 2 0 00-.58-1.44l-9.93-9.93a2 2 0 00-2.83 0l-8.17 8.17a11.33 11.33 0 00-4 7.5c-.19 3 1.13 6 3.9 8.76l10.06 10 .11.11a8 8 0 004.92 1.96 6.19 6.19 0 004.5-2c3.39-3.38 1.62-8 .22-10a2.62 2.62 0 00-.22-.25L51 91.56l6.6-6.26a2 2 0 00.6-1.43zm-11.46 6.2a2 2 0 000 2.87l6.51 6.51c.37.58 1.79 3.06.14 4.71s-3.35.38-3.91-.07l-10-10c-1.92-1.92-2.84-3.82-2.74-5.66a7.38 7.38 0 012.56-4.71l.18-.16 6.82-6.82 7.07 7.06z\" class=\"cls-1\"/><path d=\"M92.73 61.51l-14-14.22c-.9-1.2-5.88-7-18.73-7s-17.84 5.85-18.74 7l-14 14.22a2.52 2.52 0 00-.21.25c-1.26 1.8-2.83 6 .25 8.94s7.47.68 9.35-1l7.62-7.7v5.1a2 2 0 002 2H73.7a2 2 0 002-2V62l7.7 7.69a9.13 9.13 0 005.69 2.48 5.07 5.07 0 003.6-1.48c3.08-3 1.51-7.14.25-8.94a2.52 2.52 0 00-.21-.24zm-2.81 6.31c-1.22 1.16-3.75-1-3.75-1l-11-11a2 2 0 00-3.42 1.41v7.93H48.27v-7.98a2 2 0 00-3.42-1.41l-11 11s-2.57 2.23-3.8 1.05-.13-3.15.18-3.65L44.22 50a3.5 3.5 0 00.24-.3c.15-.22 3.85-5.45 15.53-5.45s15.37 5.23 15.51 5.43a2.51 2.51 0 00.25.32l14 14.18c.3.5 1.39 2.46.17 3.64z\" class=\"cls-1\"/><path fill=\"#bfc5d2\" d=\"M60 37.58a12.83 12.83 0 10-12.84-12.82A12.83 12.83 0 0060 37.58zm0-21.65a8.83 8.83 0 11-8.83 8.83A8.84 8.84 0 0160 15.93z\"/>",
    "attrs": {
      "data-name": "Layer 1",
      "viewBox": "0 0 120 120"
    }
  };
  _exports.default = _default;
});