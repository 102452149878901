define("ember-svg-jar/inlined/icons/app/app.venue", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M60 3a56.83 56.83 0 1056.83 56.83A56.89 56.89 0 0060 3zM8 50.37h14.1s3.1-.15 3.1-3.1-3.2-3.19-3.2-3.19h-4.15a3.24 3.24 0 01-3.32-3.32 3.17 3.17 0 013.28-3.28h10.77a2.86 2.86 0 002.88-2.88c0-2.83-3.27-3.27-3.27-3.27H15.58a52.72 52.72 0 0174.36-14.95l-12.06.14s-3.26.47-3.23 3.31a2.86 2.86 0 002.92 2.84l17.76-.12a3.17 3.17 0 013.32 3.24 3.24 3.24 0 01-3.28 3.36h-4.16s-3.19.28-3.15 3.23 3.14 3.07 3.14 3.07l15.55-.19a52.7 52.7 0 011.07 47V65.2a1.63 1.63 0 000-.31v-.12a2.46 2.46 0 00-.14-.38 2.25 2.25 0 00-.24-.36l-.07-.08a1.71 1.71 0 00-.25-.22L61 29.75a1.08 1.08 0 00-.24-.12 1.2 1.2 0 00-.3-.15 1.65 1.65 0 00-.32-.07 1.86 1.86 0 00-.33 0 1.65 1.65 0 00-.31 0 1.94 1.94 0 00-.33.07 1.2 1.2 0 00-.3.15 1.08 1.08 0 00-.24.12l-46.09 33.9a1.88 1.88 0 00-.25.24l-.07.06a2.25 2.25 0 00-.24.36v.09a2 2 0 00-.11.32.58.58 0 000 .14 1.43 1.43 0 000 .29.2.2 0 000 .07v16a52.54 52.54 0 01-4.7-21.35 53.2 53.2 0 01.83-9.5zM59.77 35.7l19 36.54A105.76 105.76 0 0141 71.83zM36.94 71a111.47 111.47 0 01-19.29-6.13L54 38.06zm18.27 6.56L39.94 92.82V75.68a117.21 117.21 0 0015.27 1.87zM79.6 92.82L64.47 77.69a102.33 102.33 0 0015.13-1.55zm3.23-21.39L65.51 38.06 102 64.91a93.18 93.18 0 01-19.17 6.52zm-67.1 17.21V68.38a114.92 114.92 0 0020.21 6.47v32a53.06 53.06 0 01-20.21-18.21zm67.87 18.47V75.35a97.26 97.26 0 0020.22-6.88v20.17a2.12 2.12 0 00.09.57 53.16 53.16 0 01-20.31 17.9z\"/><path fill-opacity=\".5\" d=\"M8 50.37a44.92 44.92 0 017.54-19h12.65s3.28.45 3.27 3.27a2.87 2.87 0 01-2.88 2.88H17.81a3.22 3.22 0 00-3.28 3.28c0 3.29 3.32 3.32 3.32 3.32H22a3.37 3.37 0 013.19 3.19c.11 3-3.1 3.1-3.1 3.1zm81.87-33.94L78 16.52S74.52 17 74.7 20s3.3 2.66 3.3 2.66l17.3-.14s3.49 0 3.4 3.25a3.21 3.21 0 01-3 3.35l-4.46.05s-3.26.42-3.07 3.39a3.16 3.16 0 003.26 2.94l15.3-.24S99 21.45 89.87 16.43z\"/>",
    "attrs": {
      "viewBox": "0 0 120 120",
      "fill": "currentColor"
    }
  };
  _exports.default = _default;
});