define("ember-svg-jar/inlined/icons/app/app.get-involved", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M60 3a56.77 56.77 0 00-36.11 13 7.76 7.76 0 00-6.46-3.31c-5.76 0-8.17 5.46-8.64 8.35a1.73 1.73 0 000 .32v14a2.12 2.12 0 00-.21.36 56.28 56.28 0 00-5.41 24.15A56.83 56.83 0 1060 3zM7.17 59.87A53.12 53.12 0 018.76 47v1.14c0 11.78 10 16.88 16.58 18.27v33.28A52.7 52.7 0 017.17 59.87zm59.35 52.41V64.22a2 2 0 00-4 0v48.42q-1.26.06-2.52.06a52.58 52.58 0 01-30.7-9.87 2.2 2.2 0 000-.36V64.72a2 2 0 00-1.76-2c-.6-.07-14.82-2-14.82-14.59V21.54c.17-.83 1.19-4.86 4.67-4.86s4.39 3.94 4.53 4.69v23.48c.16 2.84 2.1 8.23 8.34 8.23h8.24a2 2 0 00.93-3.77c-.14-.08-3.44-2-3.44-8.66a10.43 10.43 0 0110.53-10.49C54.46 30.16 58 35.9 58 40c0 7-2.78 9.17-2.85 9.23a2 2 0 001.11 3.67h5.64c.52 0 12.76.53 12.76 12.7v28.18a2 2 0 004 0V65.57c0-12.8-10.88-16.52-16.7-16.7h-1.69A20.22 20.22 0 0062 40a13.74 13.74 0 00-7.76-12 8.63 8.63 0 017.84-4.63c5.91 0 9.36 3.85 9.72 7.66a11.18 11.18 0 01-2.74 8.79A2 2 0 0070.24 43l3.79.69c.5.11 12.28 2.79 12.28 13.25v25.31a2 2 0 002 2 2 2 0 002-2v-25.3c0-11-10.1-16-15.5-17.17l-.81-.14a15.29 15.29 0 001.8-9.06c-.52-5.47-5.5-11.28-13.71-11.28a12.77 12.77 0 00-11.66 7.29 18.2 18.2 0 00-3.93-.43A14.35 14.35 0 0032 40.65a16.69 16.69 0 002 8.43h-3.7c-3.78 0-4.28-3.59-4.34-4.41V21.21a2.83 2.83 0 000-.29 11.25 11.25 0 00-.25-1.2 52.82 52.82 0 1140.83 92.56z\"/><path fill-opacity=\".5\" d=\"M56.08 62.86H45.81a2 2 0 00-2 2v12.29a2 2 0 002 2h1.71a2 2 0 002-2v-2.38H52a2 2 0 002-2v-2.53h2.08a2 2 0 002-2v-3.38a2 2 0 00-2-2z\"/>",
    "attrs": {
      "viewBox": "0 0 120 120",
      "fill": "currentColor"
    }
  };
  _exports.default = _default;
});