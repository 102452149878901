define("ember-svg-jar/inlined/icons/app/app.bars", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M60 3.89a56.83 56.83 0 1056.83 56.83A56.9 56.9 0 0060 3.89zm6.94 57.27a19.21 19.21 0 019.61-16.42 2 2 0 001-1.71V31.61h7.07V43a2 2 0 001 1.71 19.32 19.32 0 019.6 15.88c-6.72 9.52-22.77 10.6-28.29 10.68zM80.29 23.9h4.28v3.71H77.5V23.9zM66.93 75.29c5.34-.05 19.54-1 28.21-8.7v21.17c-6.64 9.49-22.69 10.57-28.21 10.65zm0 27.14c5.34-.05 19.54-1 28.21-8.7v6.38a52.69 52.69 0 01-28.22 13v-.07zm32.21-6.28v-34.8a23.15 23.15 0 00-10.57-19.42V31.61a2 2 0 000-4V21.9a2 2 0 00-2-2H75.5a2 2 0 00-2 2v5.71h-.15a2 2 0 000 4h.15v10.32a23.14 23.14 0 00-10.57 19.32V113a1.74 1.74 0 00.06.45c-1 .05-2 .09-3 .09a52.9 52.9 0 1139.15-17.4z\"/><path fill-opacity=\".5\" d=\"M55.58 33.11H24.26a2 2 0 00-2 1.69c-1.47 9.44.53 17.83 2.64 26.72l.38 1.61c2.78 11.76 2.25 26.11 2.25 26.26a2 2 0 002 2.08h20.7a2 2 0 002-2.08 105 105 0 012-25.41c4.21-12.74 4.13-22.87 3.32-29.13a2 2 0 00-1.97-1.74zm-26.39 29.1l-.38-1.62c-2-8.28-3.68-15.5-2.8-23.48h27.78a53.6 53.6 0 01.2 6.18H29.5s-.13 5.61 2.5 14.5c2.11 7.17 3.34 18.56 3.21 29.68h-3.62c.05-4.47-.1-15.55-2.4-25.26z\"/>",
    "attrs": {
      "data-name": "Layer 1",
      "viewBox": "0 0 120 120",
      "fill": "currentColor"
    }
  };
  _exports.default = _default;
});