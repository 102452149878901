define("ember-svg-jar/inlined/bg.grid", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>bg.grid</title><g fill=\"none\" fill-rule=\"evenodd\"><path fill=\"#FFF\" d=\"M-.5 0h224v40H-.5z\"/><path fill=\"#EEEEF4\" d=\"M223.5 0h1v40h-1zM-.5 0h1v40h-1z\"/></g>",
    "attrs": {
      "width": "224",
      "height": "40",
      "viewBox": "0 0 224 40",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});