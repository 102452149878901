define("ember-svg-jar/inlined/icons/app/app.sensory-spectacle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M876 502.2L752.6 622.5c-9.8 9.5-14.2 23.3-11.9 36.7L769.9 829c5.8 33.8-29.7 59.6-60.1 43.6l-152.5-80.2c-12.1-6.3-26.5-6.3-38.5 0l-152.5 80.2c-30.4 16-65.9-9.8-60.1-43.6l29.1-169.9c2.3-13.4-2.1-27.1-11.9-36.7L199.9 502.2c-24.6-24-11-65.7 22.9-70.7l170.5-24.7c13.5-1.9 25.2-10.4 31.2-22.7l76.3-154.5c15.1-30.8 59.1-30.8 74.3 0l76.2 154.5c6.1 12.2 17.7 20.8 31.2 22.7L853 431.5c34 5 47.6 46.7 23 70.7M540 0C241.8 0 0 241.8 0 540s241.8 540 540 540 540-241.7 540-540S838.2 0 540 0\"/>",
    "attrs": {
      "viewBox": "0 0 1080 1080",
      "fill": "currentColor"
    }
  };
  _exports.default = _default;
});