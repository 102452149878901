define("ember-svg-jar/inlined/icons/app/app.more", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M6.7 13.6a2.7 2.7 0 100 5.4 2.7 2.7 0 000-5.4zm9 0a2.7 2.7 0 100 5.4 2.7 2.7 0 000-5.4zm9.1 0a2.7 2.7 0 100 5.4 2.7 2.7 0 000-5.4z\"/>",
    "attrs": {
      "viewBox": "0 0 32 32",
      "fill": "currentColor"
    }
  };
  _exports.default = _default;
});