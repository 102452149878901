define("@cloudcreativity/ember-quill/components/quill/toolbar/button", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component"], function (_exports, _component, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <button type="button" class={{this.qlClass}} ...attributes>
    {{yield}}
  </button>
  
  */
  {
    "id": "0Et+hQVg",
    "block": "[[[11,\"button\"],[24,4,\"button\"],[16,0,[30,0,[\"qlClass\"]]],[17,1],[12],[1,\"\\n  \"],[18,2,null],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&attrs\",\"&default\"],false,[\"yield\"]]",
    "moduleName": "@cloudcreativity/ember-quill/components/quill/toolbar/button.hbs",
    "isStrictMode": false
  });

  class QuillToolbarButtonComponent extends _component2.default {
    get qlClass() {
      if (this.args.type) {
        return `ql-${this.args.type}`;
      }

      return null;
    }

  }

  _exports.default = QuillToolbarButtonComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, QuillToolbarButtonComponent);
});