define("ember-svg-jar/inlined/icon.camera-booth", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<defs><style>.cls-1{fill:#fff}</style></defs><rect width=\"400\" height=\"400\" rx=\"94.02\" ry=\"94.02\"/><path class=\"cls-1\" d=\"M316.73 322.69H83.27c-21.45 0-38.91-17.45-38.91-38.91V141.11c0-21.45 17.46-38.91 38.91-38.91h44.94l22.09-33.13c2.41-3.61 6.46-5.78 10.79-5.78h77.82c4.34 0 8.39 2.17 10.79 5.78l22.09 33.13h44.94c21.45 0 38.91 17.45 38.91 38.91v142.67c0 21.45-17.46 38.91-38.91 38.91zM83.27 128.14c-7.15 0-12.97 5.82-12.97 12.97v142.67c0 7.15 5.82 12.97 12.97 12.97h233.46c7.15 0 12.97-5.82 12.97-12.97V141.11c0-7.15-5.82-12.97-12.97-12.97h-51.88c-4.34 0-8.39-2.17-10.79-5.78l-22.09-33.13h-63.94l-22.09 33.13a12.981 12.981 0 01-10.79 5.78H83.27z\"/><path class=\"cls-1\" d=\"M200 270.81c-35.76 0-64.85-29.09-64.85-64.85s29.09-64.85 64.85-64.85 64.85 29.09 64.85 64.85-29.09 64.85-64.85 64.85zm0-103.76c-21.45 0-38.91 17.45-38.91 38.91s17.46 38.91 38.91 38.91 38.91-17.45 38.91-38.91-17.46-38.91-38.91-38.91z\"/>",
    "attrs": {
      "id": "Layer_1",
      "data-name": "Layer 1",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 400 400"
    }
  };
  _exports.default = _default;
});