define("ember-svg-jar/inlined/icons/app/splash.mix", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>splash.mix</title><g fill=\"none\" fill-rule=\"evenodd\"><path fill=\"#000\" fill-rule=\"nonzero\" opacity=\".197\" d=\"M0 0h368v207H0z\"/><path d=\"M157 76h55v55h-55z\"/><path d=\"M164 96.223v13.75h9.167l11.458 11.458V84.765l-11.458 11.458H164zm30.938 6.875c0-4.056-2.338-7.54-5.73-9.235v18.447a10.253 10.253 0 005.73-9.212zM189.208 83v4.72c6.623 1.972 11.459 8.113 11.459 15.378 0 7.264-4.836 13.406-11.459 15.377v4.72c9.19-2.085 16.042-10.289 16.042-20.097 0-9.808-6.852-18.013-16.042-20.098z\" fill=\"#000\" fill-rule=\"nonzero\"/></g>",
    "attrs": {
      "width": "368",
      "height": "207",
      "viewBox": "0 0 368 207",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});