define("ember-svg-jar/inlined/icons/app/app.positive", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<defs><clipPath id=\"a\"><path d=\"M414 0v896H0V0h414z\"/></clipPath><clipPath id=\"b\"><path d=\"M27 0c14.912 0 27 12.088 27 27S41.912 54 27 54 0 41.912 0 27 12.088 0 27 0z\"/></clipPath><clipPath id=\"c\"><path d=\"M1.87 0a1.87 1.87 0 011.871 1.87v28.66a1.87 1.87 0 11-3.741 0V1.87A1.87 1.87 0 011.87 0z\"/></clipPath><clipPath id=\"d\"><path d=\"M1.87 0a1.87 1.87 0 011.871 1.87v28.66a1.87 1.87 0 11-3.741 0V1.87A1.87 1.87 0 011.87 0z\"/></clipPath></defs><g clip-path=\"url(#a)\" transform=\"translate(-328 -670)\"><g clip-path=\"url(#b)\" transform=\"translate(328 670)\"><path fill=\"#26BDEF\" d=\"M0 0h54v54H0V0z\"/></g><g clip-path=\"url(#c)\" transform=\"translate(353.13 680.8)\"><path fill=\"#FFF\" d=\"M0 0h3.741v32.4H0V0z\"/></g><g clip-path=\"url(#d)\" transform=\"rotate(90 -161.965 533.165)\"><path fill=\"#FFF\" d=\"M0 0h3.741v32.4H0V0z\"/></g></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "54",
      "height": "54",
      "viewBox": "0 0 54 54"
    }
  };
  _exports.default = _default;
});