define("ember-svg-jar/inlined/sesh/icon.lineup", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\"><path stroke=\"#000\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\".777\" d=\"M24.005 43.055c-.59 4.067.066 6.56 4.724 6.56 3.824 0 3.805-7.347 4.067-10.496.394-4.068.263-15.022 9.183-11.15\"/><path fill=\"#FFF\" fill-rule=\"nonzero\" d=\"M26.399 45.351h-5.117l-2.36-25.715h9.839z\"/><path stroke=\"#000\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\".777\" d=\"M26.399 45.351h-5.117l-2.36-25.715h9.839z\"/><path fill=\"#EDBF2A\" fill-rule=\"nonzero\" d=\"M32.108 14.65a8.267 8.267 0 11-16.534 0 8.267 8.267 0 0116.534 0\"/><path stroke=\"#000\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\".777\" d=\"M32.108 14.65a8.267 8.267 0 11-16.534 0 8.267 8.267 0 0116.534 0h0z\"/><path stroke=\"#FFF\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\".777\" d=\"M19.085 15.077a5.05 5.05 0 015.05-5.051\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 56 56"
    }
  };
  _exports.default = _default;
});