define("ember-svg-jar/inlined/sesh/icon.timetable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>icon.timetable</title><g fill=\"none\" fill-rule=\"evenodd\"><path fill=\"#FEFEFE\" fill-rule=\"nonzero\" d=\"M11.595 44.889h3.238V16.107h-3.238z\"/><path stroke=\"#07080C\" stroke-width=\".777\" stroke-linecap=\"round\" stroke-linejoin=\"round\" d=\"M11.595 44.889h3.238V16.107h-3.238z\"/><path fill=\"#FEFEFE\" fill-rule=\"nonzero\" d=\"M41.168 44.889h3.238V16.107h-3.238z\"/><path stroke=\"#07080C\" stroke-width=\".777\" stroke-linecap=\"round\" stroke-linejoin=\"round\" d=\"M41.168 44.889h3.238V16.107h-3.238zM14.768 12.665a1.555 1.555 0 11-3.109 0 1.555 1.555 0 013.109 0h0zM44.341 12.665a1.554 1.554 0 11-3.108 0 1.554 1.554 0 013.108 0h0z\"/><path d=\"M16.92 18.439a18.926 18.926 0 0022.248 0c.076 1.012.083 2.046.018 3.095a21.52 21.52 0 01-22.285 0 22.698 22.698 0 01.018-3.095\" fill=\"#EDBF2A\" fill-rule=\"nonzero\"/><path d=\"M16.92 18.439a18.926 18.926 0 0022.248 0c.076 1.012.083 2.046.018 3.095a21.52 21.52 0 01-22.285 0 22.698 22.698 0 01.018-3.095h.001z\" stroke=\"#07080C\" stroke-width=\".777\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/></g>",
    "attrs": {
      "width": "56",
      "height": "56",
      "viewBox": "0 0 56 56",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});