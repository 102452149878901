define("ember-svg-jar/inlined/icons/app/app.food", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M116.83 60.92a56.84 56.84 0 10-99.54 37.5 1.87 1.87 0 00.67.49v5.37a2 2 0 002 2h5.84a1.21 1.21 0 00.16.16 56.85 56.85 0 0068.1 0l.14-.13h5.8a2 2 0 002-2v-5.4a1.87 1.87 0 00.67-.49 56.8 56.8 0 0014.16-37.5zM60 113.75a52.28 52.28 0 01-27.08-7.47h54.15A52.25 52.25 0 0160 113.75zm38-11.47H22V93h76zM22.15 59.84a19.19 19.19 0 0119-16.33h37.68a19.19 19.19 0 0119 16.33zm69.34 4V70H27.82v-6.16zm.38 18.82a1.92 1.92 0 00-.38 1.16V89H27.82v-5.18a1.92 1.92 0 00-.38-1.16zM100 89h-4.51v-5.18a1.92 1.92 0 00-.38-1.16h5.11a6.33 6.33 0 000-12.65h-4.73v-6.17H100a2 2 0 001.45-.61 2 2 0 00.55-1.47 23.17 23.17 0 00-23.2-22.25H41.16A23.17 23.17 0 0018 61.76a2 2 0 00.55 1.47 2 2 0 001.45.61h3.86V70h-4.09a6.33 6.33 0 000 12.65h4.43a1.92 1.92 0 00-.38 1.16V89H20a2 2 0 00-2 2v1.9a52.84 52.84 0 1184.07 0V91a2 2 0 00-2.07-2z\"/><path d=\"M38.51 49.3A2.48 2.48 0 1041 51.78a2.48 2.48 0 00-2.49-2.48zm14.32-2a2.48 2.48 0 102.48 2.48 2.48 2.48 0 00-2.48-2.48zm14.33 4a2.48 2.48 0 102.47 2.48 2.48 2.48 0 00-2.47-2.48zm14.32-2A2.48 2.48 0 1084 51.78a2.48 2.48 0 00-2.52-2.48z\" class=\"cls-2\"/><rect width=\"93.09\" height=\"12.65\" x=\"13.45\" y=\"70.01\" fill-opacity=\".5\" rx=\"6.33\"/>",
    "attrs": {
      "viewBox": "0 0 120 120",
      "fill": "currentColor"
    }
  };
  _exports.default = _default;
});