define("ember-svg-jar/inlined/pride/menu.timetable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"none\"><path fill=\"#FFF\" d=\"M19.157 0h-.933a1.064 1.064 0 00-1.064 1.065v16.114a1.065 1.065 0 001.064 1.065h.933c.588 0 1.065-.477 1.065-1.065V1.065C20.222.477 19.745 0 19.157 0zm.355 17.18c0 .195-.16.354-.355.354h-.933a.355.355 0 01-.354-.355V1.065c0-.196.159-.355.354-.355h.933c.196 0 .355.16.355.355v16.114z\"/><path fill=\"#FFF\" d=\"M18.11 17.558l-.47.511-.01.012c-.039-.035-3.856-3.506-6.59-3.506H5.191a1.908 1.908 0 01-1.893-1.645 1.846 1.846 0 01-.019-.268V5.647a1.914 1.914 0 011.912-1.912h5.849c2.289 0 5.358-2.454 6.244-3.206l.011.015.449.527c-.163.138-.36.3-.585.48-1.35 1.07-3.714 2.701-5.765 2.878a4.35 4.35 0 01-.354.015H5.19c-.662 0-1.201.54-1.201 1.202v7.016c0 .663.54 1.202 1.201 1.202h5.85c.116 0 .235.006.354.017 2.073.178 4.468 1.847 5.764 2.876.55.436.903.757.95.8z\"/><path fill=\"#FFF\" d=\"M3.704 12.942h-.115C1.61 12.942 0 11.332 0 9.352v-.396c0-1.978 1.61-3.589 3.59-3.589v.71a2.882 2.882 0 00-2.88 2.88v.395a2.882 2.882 0 002.88 2.879h.114v.71z\"/><path fill=\"#F366BA\" d=\"M10.684 4.444v9.42H5.191c-.662 0-1.201-.54-1.201-1.202V5.647c0-.662.54-1.203 1.201-1.203h5.493z\"/><path fill=\"#FFF\" d=\"M10.684 4.089h1V14.22h-1zm9.164 7.017v-.71c.685 0 1.242-.557 1.242-1.241 0-.685-.557-1.242-1.242-1.242v-.71c1.077 0 1.952.875 1.952 1.952a1.954 1.954 0 01-1.952 1.951zM22.313 8.8h1.88v1h-1.88zm-1.36-1.998l1.33-1.33.503.502-1.33 1.33zm0 4.707l.503-.503 1.33 1.33-.503.502zM9.225 20.736H5.936a.355.355 0 01-.352-.313l-.745-6.16.705-.086.707 5.849h2.56l-.873-5.753.703-.106.934 6.16a.354.354 0 01-.35.409z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24.193",
      "height": "20.736"
    }
  };
  _exports.default = _default;
});