define("ember-svg-jar/inlined/icons/app/app.ticketed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<defs><path id=\"a\" d=\"M0 0h114.883v115.128H0z\"/></defs><g fill=\"none\" fill-rule=\"evenodd\" transform=\"translate(3 2)\"><mask id=\"b\" fill=\"#fff\"><use xlink:href=\"#a\"/></mask><path fill=\"currentColor\" d=\"M97.86 57.564c0 7.605 5.682 13.91 13.023 14.891v10.178H4V72.461c7.367-.957 13.077-7.273 13.077-14.897 0-7.624-5.71-13.94-13.077-14.898V32.494h106.883v10.179c-7.341.981-13.022 7.285-13.022 14.891m-40.422 53.564c-18.224 0-35.16-9.302-44.992-24.495h89.989c-9.832 15.193-26.768 24.495-44.997 24.495M57.445 4c18.224 0 35.158 9.302 44.99 24.494H12.448C22.28 13.303 39.217 4 57.445 4m55.438 42.541a2 2 0 002-2V30.494a2 2 0 00-2-2h-5.758C96.805 10.89 77.883 0 57.445 0 37.003 0 18.079 10.89 7.76 28.494H2a2 2 0 00-2 2v14.049a2 2 0 002 2c.029 0 .067 0 .104-.002 6.055.027 10.973 4.961 10.973 11.023 0 6.062-4.918 10.996-10.972 11.023-.037-.002-.075-.002-.105-.002a2 2 0 00-2 2v14.048a2 2 0 002 2h5.76c10.32 17.604 29.24 28.495 49.679 28.495 20.443 0 39.366-10.891 49.686-28.495h5.758a2 2 0 002-2V70.587a2 2 0 00-2-2c-6.078 0-11.022-4.945-11.022-11.023 0-6.078 4.944-11.023 11.022-11.023\" mask=\"url(#b)\"/><path fill=\"currentColor\" fill-opacity=\".5\" d=\"M25.815 35.052a2 2 0 00-2 2v.5a2 2 0 004 0v-.5a2 2 0 00-2-2m0 24.15a2 2 0 00-2 2v.91a2 2 0 004 0v-.91a2 2 0 00-2-2m0 8.187a2 2 0 00-2 2v.91a2 2 0 004 0v-.91a2 2 0 00-2-2m0-16.373a2 2 0 00-2 2v.91a2 2 0 004 0v-.91a2 2 0 00-2-2m0-8.187a2 2 0 00-2 2v.91a2 2 0 004 0v-.91a2 2 0 00-2-2m0 32.747a2 2 0 00-2 2v.5a2 2 0 004 0v-.5a2 2 0 00-2-2M56.628 37.724H37.373a2 2 0 00-2 2v35.163a2 2 0 002 2h19.255a2 2 0 002-2V39.724a2 2 0 00-2-2m33 0H69.35a2 2 0 000 4h20.278a2 2 0 000-4M69.35 49.236h14.883a2 2 0 000-4H69.35a2 2 0 000 4m20.278 3.512H69.35a2 2 0 000 4h20.278a2 2 0 000-4m-10.79 7.511H69.35a2 2 0 000 4h9.488a2 2 0 000-4\"/></g>",
    "attrs": {
      "xmlns:xlink": "http://www.w3.org/1999/xlink",
      "viewBox": "0 0 120 120"
    }
  };
  _exports.default = _default;
});