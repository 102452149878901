define("ember-svg-jar/inlined/icons/app/app.first-aid", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M60 3.89a56.83 56.83 0 1056.84 56.83A56.9 56.9 0 0060 3.89zm0 109.66a52.83 52.83 0 1152.84-52.83A52.89 52.89 0 0160 113.55z\" class=\"cls-1\"/><path d=\"M101.45 44.63H74.78V17.95a2 2 0 00-2-2H52.5a2 2 0 00-2 2v26.68h-3.66V25.79a2 2 0 10-4 0v18.84h-19a2 2 0 00-2 2v20.29a2 2 0 002 2h26.68v26.67a2 2 0 002 2h20.29a2 2 0 002-2V76.75h19a2 2 0 000-4h-19v-3.87h26.68a2 2 0 002-2V46.59a2 2 0 00-2.04-1.96zm-2 20.28H72.77a2 2 0 00-2 2v26.68H54.48V66.91a2 2 0 00-2-2H25.8V48.63h26.67a2 2 0 002-2V19.96h16.29v26.67a2 2 0 002 2h26.68z\" class=\"cls-1\"/><path fill=\"#e0e8ac\" d=\"M65.11 101.38H46.82V74.7a2 2 0 00-2-2H18.14V54.41a2 2 0 00-4 0V74.7a2 2 0 002 2h26.68v26.68a2 2 0 002 2h20.29a2 2 0 100-4z\"/>",
    "attrs": {
      "data-name": "Layer 1",
      "viewBox": "0 0 120 120"
    }
  };
  _exports.default = _default;
});