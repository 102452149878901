define("ember-svg-jar/inlined/icons.car-parking", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M654.48 367.07c-6.54-10.97-16.49-20.24-29.9-27.81-13.41-7.5-31.39-11.32-54.02-11.32H477.7V521h91.99c19.62 0 36-3.33 49.08-9.98 13.08-6.65 23.36-15.08 30.81-25.19 7.45-10.19 12.79-20.74 16.06-31.78 3.27-10.97 4.94-20.59 4.94-28.66 0-6.37-1.05-14.93-3.12-25.69-2.11-10.69-6.43-21.58-12.97-32.63z\"/><path d=\"M747.56 4.04H325.85C145.89 4.04 0 149.93 0 329.9v421.71c0 179.96 145.89 325.85 325.85 325.85h421.71c179.96 0 325.85-145.89 325.85-325.85V329.9c0-179.96-145.89-325.85-325.85-325.85zm40.4 496.57c-8.03 26.33-20.82 50.24-38.36 71.69-17.58 21.44-41.09 38.71-70.56 51.73-29.46 13.09-65.61 19.6-108.48 19.6H477.7v202.61H348.25V206.21h199.09c54.17 0 100.02 8.85 137.51 26.54 37.49 17.69 66.05 42.6 85.71 74.8 19.66 32.13 29.46 70.27 29.46 114.29 0 26.12-4 52.37-12.06 78.77z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "500",
      "height": "500",
      "viewBox": "0 0 1080 1080"
    }
  };
  _exports.default = _default;
});