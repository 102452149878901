define("ember-svg-jar/inlined/icons/app/app.join-in", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M723.3 786.8c-83.1 33.3-224.4 103.8-225.8 104.5-.5.3-1 .5-1.6.7-5.7 2.4-12.9 3.9-20.6 3.9-18.9 0-41.2-8.9-55.2-36.2-2.1-4.1-3.9-8.1-5.3-11.9-15.4 5.5-33.5 8.8-51 4.4-13.7-3.4-32.6-12.8-44.9-38.6-5.5-10.5-7.8-19.9-8.1-28.1-6.1-.5-12.1-1.7-17.8-3.8-15-5.5-26.4-16.2-33.1-31-10.7-23.8-6.8-41.8-1.6-52.6 1-2.1 2.2-4.2 3.4-6.1-10.9-10.2-19.1-24.6-19.1-44.4 0-38.7 22.5-56.7 37.6-62.1l96.1-47.2c11.7-5.7 25.8-1 31.5 10.8 5.7 11.7 1 25.8-10.8 31.5l-97.6 48c-2.1 1-1.9 1.3-3.6 1.5-1.8 1.1-6.2 5-6.2 17.5 0 8.7 7.5 13.3 13.3 15.6l172-89.1 21.7 41.8-184.7 95.8-.4-.7c-.1.1-.3.2-.5.2-2.3.7-8.6 3.7-10.4 7.4-1.2 2.5-.4 7.2 2.1 12.8 1.5 3.2 3.2 4.8 6.2 5.9 8.9 3.3 23.3.5 34.7-3.2 9-4.6 16.6-6.4 18.3-6.8.3-.1.5 0 .8-.1l156.4-81.1 21.7 41.8-183 94.9c-.1 1.7.6 4.7 2.9 9 .2.3.4.6.5 1 4.8 10.1 10.1 12.5 13.8 13.4 10.2 2.5 24.6-2.6 36.2-8.6l-.6-1.4 19.2-9.9c.2-.1.5-.3.6-.4h.1l164.7-85.4 21.7 41.8-159.7 82.8c.3 2.4 1.4 6.4 4.7 12.7 1.6 3.2 6.6 12.8 15.8 10.2 16.2-8.1 146.7-72.9 227.9-105.4 65.4-26.2 166.4-118.3 201-150.9L767.9 299.5c-5.5-11.8-.5-25.8 11.2-31.4 11.8-5.6 25.8-.6 31.4 11.2l146.1 308.1c4.3 9.1 2.4 19.9-4.8 26.9-5.6 5.6-140.3 137.2-228.5 172.5M216.8 594.3c-1.2 3-2.9 5.6-5 7.8-4.4 4.6-10.6 7.3-17 7.3-2.8 0-5.6-.5-8.4-1.6-12.1-4.6-18.2-18.3-13.5-30.4l122.3-318.5c2.6-6.7 8-11.9 14.8-14 6.8-2.2 14.3-1.1 20.2 2.8 35.7 23.8 95.9-1.1 116.1-12.7 5.8-3.3 12.8-4.1 19.2-1.9l281.9 93.4c10.7 3.5 17.3 14 16 25.2-4.6 38.7-19.5 65.5-44.3 79.7-34.2 19.5-72.3 5.9-80.7 2.4l-82.5-26.6c-16.1 39.4-41.3 68.2-75.1 85.9-58.3 30.4-123.9 17.1-146.3 11.1-55.1-6.3-100.2 59.3-116.4 86.4l-1.3 3.7zM540 0C241.8 0 0 241.8 0 540s241.8 540 540 540 540-241.7 540-540S838.2 0 540 0\"/>",
    "attrs": {
      "viewBox": "0 0 1080 1080",
      "fill": "currentColor"
    }
  };
  _exports.default = _default;
});