define("ember-svg-jar/inlined/icons/app/icon.no-events", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\"><g transform=\"translate(0 17.5)\"><rect width=\"120\" height=\"56\" fill=\"#F3F4F6\" rx=\"8\"/><rect width=\"50\" height=\"11\" x=\"10.264\" y=\"10.804\" fill=\"#D1D5DB\" rx=\"3\"/><rect width=\"75\" height=\"8.5\" x=\"10.264\" y=\"25\" fill=\"#FFF\" rx=\"3\"/><rect width=\"63\" height=\"8.5\" x=\"10.264\" y=\"36.696\" fill=\"#FFF\" rx=\"3\"/></g><circle cx=\"89.764\" cy=\"17.5\" r=\"16.5\" fill=\"#FFF\" stroke=\"#E5E7EB\" stroke-width=\"2\"/><g fill=\"#D1D5DB\" transform=\"translate(80.006 7.742)\"><rect width=\"3.736\" height=\"23.864\" x=\"7.89\" y=\"-2.174\" rx=\"1.868\" transform=\"rotate(45 9.758 9.758)\"/><rect width=\"3.736\" height=\"23.864\" x=\"7.89\" y=\"-2.174\" rx=\"1.868\" transform=\"rotate(135 9.758 9.758)\"/></g></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "120",
      "height": "74",
      "viewBox": "0 0 120 74"
    }
  };
  _exports.default = _default;
});