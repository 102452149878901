define("ember-svg-jar/inlined/logo-solid.freedom", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>logo</title><g fill=\"currentColor\" fill-rule=\"evenodd\"><path d=\"M0 0h25v9.613h-4.65V19.7h-4.92V30H0zM41.3 0c.148.003.297 0 .446 0C47.41 0 52 4.477 52 10c0 5.412-4.408 9.82-9.914 9.995L52 30H27V0h14.3zM54 0v30h25V19.863h-5.083v-9.697h-4.695V0zM81 0v30h25V19.863h-5.083v-9.697h-4.695V0zM117.758 30C126.176 30 133 23.284 133 15c0-8.284-6.824-15-15.242-15H108v30h9.758z\"/><ellipse cx=\"149.5\" cy=\"15\" rx=\"15.5\" ry=\"15\"/><path d=\"M167 30V0l16.136 19.55L199 0v30z\"/></g>",
    "attrs": {
      "viewBox": "0 0 199 30"
    }
  };
  _exports.default = _default;
});