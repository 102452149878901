define("ember-svg-jar/inlined/icons/app/app.make-some-noise", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M857.1 563.5l2.7-23.8 87.5 10-2.7 23.8-87.5-10zM750.5 735.2L544.1 234.5l111-45.8 206.4 500.7-111 45.8zm-357.9-26.1l-81.9-198.8c86.5-45.7 162.8-116.5 228.6-212.5l163.2 396.1c-134.1-28.7-242.3-29.1-309.9 15.2M407.2 936l-43.9-185.1 85.6-35.3 43.9 185.1-85.6 35.3zM156.1 566.3l125.5-51.7 85.3 207.1-125.4 51.7-85.4-207.1zm633.1-369.9l18.7 15-55 68.8-18.7-15 55-68.8zm106.4 193.7L807 426.6l-9.2-22.1 88.6-36.5 9.2 22.1zM540 0C241.8 0 0 241.8 0 540s241.8 540 540 540 540-241.7 540-540S838.2 0 540 0\"/>",
    "attrs": {
      "viewBox": "0 0 1080 1080",
      "fill": "currentColor"
    }
  };
  _exports.default = _default;
});