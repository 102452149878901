define("ember-svg-jar/inlined/sesh/icon.map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\"><path fill=\"#EDBF2A\" fill-rule=\"nonzero\" d=\"M42.98 45.5c-.095-1.666-4.187-2.785-9.138-2.5-4.951.286-8.887 1.868-8.791 3.534.096 1.667 4.188 2.786 9.139 2.5 4.951-.285 8.887-1.867 8.79-3.534\"/><path fill=\"#FFF\" fill-rule=\"nonzero\" d=\"M28 30.926a6.85 6.85 0 110-13.7 6.85 6.85 0 010 13.7m0-22.738c-8.774 0-15.887 7.113-15.887 15.888 0 6.034 3.364 11.283 8.319 13.972l5.042 8.697c1.125 1.94 3.927 1.94 5.052 0l5.043-8.697c4.955-2.69 8.32-7.938 8.32-13.972C43.888 15.3 36.774 8.188 28 8.188\"/><path stroke=\"#000\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\".777\" d=\"M28 30.926a6.85 6.85 0 110-13.7 6.85 6.85 0 010 13.7h0zm0-22.738c-8.774 0-15.887 7.113-15.887 15.888 0 6.034 3.364 11.283 8.319 13.972l5.042 8.697c1.125 1.94 3.927 1.94 5.052 0l5.043-8.697c4.955-2.69 8.32-7.938 8.32-13.972C43.888 15.3 36.774 8.188 28 8.188h0z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 56 56"
    }
  };
  _exports.default = _default;
});