define("ember-svg-jar/inlined/icon.first-aid", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M745.06 7.63h-420.3C145.4 7.63 0 153.03 0 332.4v420.3c0 179.36 145.4 324.76 324.76 324.76h420.3c179.36 0 324.76-145.41 324.76-324.76V332.4c0-179.37-145.4-324.76-324.76-324.76zm152.7 574.74c-6.47 52.59-43.38 83.19-101.44 84.1-43.88.69-87.79.14-135.67.14 0 46.97-.04 92.4.01 137.84.04 32.71-9.68 61.65-38.04 79.78-14.35 9.18-31.73 15.92-48.53 18.37-22.01 3.21-44.83 1.93-67.25 1.1-54.74-2.03-91.83-39.96-93.48-95.32-.7-23.36-.14-46.76-.14-70.13v-71.62c-44.69 0-88.2.06-131.72-.02-34.14-.07-64.15-8.84-86.71-37.15-49.84-62.54-20.51-186.89 49.97-206.33 15.84-4.37 33.28-3.73 50.01-3.97 38.2-.53 76.4-.17 118.46-.17 0-47.96-.22-91.82.05-135.69.38-62.42 36.25-99.91 98.08-102.3 18.69-.72 37.49-.89 56.13.27 58.09 3.64 92.38 39.8 93.07 98.2.54 45.54.11 91.09.11 139.53 44.94 0 86.81-.13 128.67.03 73.92.27 109.98 36.11 110.54 110.2.13 17.72.05 35.6-2.11 53.15z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "500",
      "height": "500",
      "viewBox": "0 0 1080 1080"
    }
  };
  _exports.default = _default;
});