define("ember-svg-jar/inlined/icons/app/app.captioned-performance", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M855.6 720.8c-23 8.7-48.1 13-75.2 13-43.4 0-81.1-9.4-113.2-28.3-32-18.8-56.6-44.2-73.5-76-17-31.9-25.5-66.9-25.5-105 0-41.8 8.4-79.2 25.5-112 16.9-32.8 40.7-58.4 71.3-76.6 30.5-18.3 65.8-27.5 105.8-27.5 25.3 0 48.7 3.4 70.1 10.2 21.5 6.8 40.3 15.3 56.6 25.5l-32.2 77.5c-29.8-22.7-60.4-34-91.6-34-21.1 0-41 5.8-59.4 17.5-18.5 11.7-33.2 27.6-44.1 47.8-11 20.2-16.4 42.5-16.4 67 0 26.8 5.1 50.3 15.3 70.4 10.2 20.2 24.7 35.9 43.6 47.2 18.9 11.3 40.9 16.9 66.2 16.9 20.4 0 38.7-3.3 54.9-9.9 16.2-6.6 29.1-14.4 38.5-23.5l34 73.6c-10.9 8.9-27.7 17.6-50.7 26.2m-394.2 0c-23 8.7-48.1 13-75.3 13-43.4 0-81.1-9.4-113.2-28.3-32-18.8-56.5-44.2-73.5-76-16.9-31.9-25.5-66.9-25.5-105 0-41.8 8.5-79.2 25.5-112s40.7-58.4 71.3-76.6c30.5-18.3 65.9-27.5 105.8-27.5 25.3 0 48.7 3.4 70.2 10.2 21.5 6.8 40.3 15.3 56.5 25.5L471 421.6c-29.8-22.7-60.3-34-91.6-34-21.1 0-40.9 5.8-59.4 17.5s-33.2 27.6-44.1 47.8c-10.9 20.2-16.4 42.5-16.4 67 0 26.8 5.1 50.3 15.3 70.4 10.2 20.2 24.7 35.9 43.6 47.2 18.8 11.3 40.9 16.9 66.2 16.9 20.4 0 38.7-3.3 54.8-9.9 16.2-6.6 29.1-14.4 38.5-23.5l34 73.6c-10.8 8.9-27.5 17.6-50.5 26.2M540 0C241.8 0 0 241.8 0 540s241.8 540 540 540 540-241.7 540-540S838.2 0 540 0\"/>",
    "attrs": {
      "viewBox": "0 0 1080 1080",
      "fill": "currentColor"
    }
  };
  _exports.default = _default;
});