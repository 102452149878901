define("ember-svg-jar/inlined/icons/app/app.contact", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M59.85 3.17A56.83 56.83 0 10116.68 60 56.89 56.89 0 0059.85 3.17zm0 109.66A52.83 52.83 0 11112.68 60a52.89 52.89 0 01-52.83 52.83z\"/><path fill-opacity=\".5\" d=\"M60.77 25.91h-.35c-19.34 0-32.72 13.46-32.72 33.33v2.48c0 19.91 12.9 32.37 32.59 32.37h.34a49.94 49.94 0 0011-1A2.7 2.7 0 0074 90.38a2 2 0 00-2.26-2 100.44 100.44 0 01-11.07 1.09h-.34c-16.34 0-27.4-10.76-27.4-28.05v-2c0-17.34 11.24-29 27.53-29h.35C76.36 30.53 87 41.07 87 55.23v1.61C87 66.56 83.29 72 78.19 72c-3.35 0-5.92-1.91-5.92-5.75v-17.9c0-1.74-1-2.83-2.57-2.83s-2.57 1-2.57 2.83v2h-.31a9 9 0 00-8.67-5.67c-7.23 0-11.59 5.41-11.59 13.9v3.88c0 8.93 4.49 14.07 11.24 14.07 4.84 0 8.76-3.23 9.54-6.14h.4c.56 3.65 4.66 6.14 9.75 6.14C85.9 76.49 92 69 92 57.28v-2.22c0-16.64-12.76-29.15-31.23-29.15zm6.31 36.38c0 6.31-3 9.49-7.58 9.49-4.7 0-7.58-3.39-7.58-10v-3c0-6 2.83-9.72 7.49-9.72s7.67 3.88 7.67 9.33z\"/>",
    "attrs": {
      "viewBox": "0 0 120 120",
      "fill": "currentColor"
    }
  };
  _exports.default = _default;
});