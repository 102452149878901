define("ember-svg-jar/inlined/icon.box-office", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<defs><style>.cls-1{fill:#fff}</style></defs><rect width=\"400\" height=\"400\" rx=\"94.02\" ry=\"94.02\"/><path class=\"cls-1\" d=\"M322.26 71.65c7.5 0 13.58 6.08 13.58 13.58v74.71c-18.76 0-33.96 15.2-33.96 33.96s15.2 33.96 33.96 33.96v74.71c0 7.5-6.08 13.58-13.58 13.58H77.74c-7.5 0-13.58-6.08-13.58-13.58v-74.71c18.76 0 33.96-15.2 33.96-33.96s-15.2-33.96-33.96-33.96V85.23c0-7.5 6.08-13.58 13.58-13.58h244.52zm-13.58 27.17H91.32v40.32l2.12 1.1a61.13 61.13 0 0131.79 50.81l.05 2.87c0 22.36-12.21 42.95-31.84 53.66l-2.12 1.11v40.32h217.35v-40.33l-2.12-1.09a61.13 61.13 0 01-31.79-50.81l-.05-2.87c0-23.15 12.86-43.29 31.84-53.66l2.12-1.13v-40.3z\"/><circle class=\"cls-1\" cx=\"241.28\" cy=\"134.08\" r=\"13.33\"/><circle class=\"cls-1\" cx=\"241.28\" cy=\"173.36\" r=\"13.33\"/><circle class=\"cls-1\" cx=\"241.28\" cy=\"212.63\" r=\"13.33\"/><circle class=\"cls-1\" cx=\"241.28\" cy=\"251.9\" r=\"13.33\"/>",
    "attrs": {
      "id": "Layer_1",
      "data-name": "Layer 1",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 400 400"
    }
  };
  _exports.default = _default;
});