define("@cloudcreativity/ember-quill/components/quill-editor", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "quill", "@ember/service", "@ember/object", "@ember/object/internals", "@ember/runloop"], function (_exports, _component, _templateFactory, _component2, _quill, _service, _object, _internals, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div id={{this.id}}
    ...attributes
    {{did-insert this.initQuill}}
    {{did-update this.updateQuill enabled=@enabled delta=@delta}}
  >
  </div>
  
  */
  {
    "id": "GOITJWaE",
    "block": "[[[11,0],[16,1,[30,0,[\"id\"]]],[17,1],[4,[38,0],[[30,0,[\"initQuill\"]]],null],[4,[38,1],[[30,0,[\"updateQuill\"]]],[[\"enabled\",\"delta\"],[[30,2],[30,3]]]],[12],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&attrs\",\"@enabled\",\"@delta\"],false,[\"did-insert\",\"did-update\"]]",
    "moduleName": "@cloudcreativity/ember-quill/components/quill-editor.hbs",
    "isStrictMode": false
  });

  let QuillEditorComponent = (_dec = (0, _service.inject)('quill'), (_class = class QuillEditorComponent extends _component2.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "quillService", _descriptor, this);

      _defineProperty(this, "quill", null);

      _defineProperty(this, "value", null);
    }

    get id() {
      return this.args.id ?? (0, _internals.guidFor)(this);
    }

    get name() {
      return this.args.name ?? this.id;
    }

    get modules() {
      let modules = this.args.modules ?? {};

      if (this.args._toolbar) {
        modules.toolbar = this.args._toolbar;
      }

      return modules;
    }

    initQuill(element) {
      this.quill = new _quill.default(element, {
        bounds: this.args.bounds,
        debug: this.args.debug ?? 'warn',
        formats: this.args.formats,
        modules: this.modules,
        placeholder: this.args.placeholder,
        readOnly: this.args.readOnly ?? false,
        scrollingContainer: this.args.scrollingContainer ?? null,
        theme: this.args.theme
      });
      this.quill.enable(Boolean(this.args.enabled ?? true));

      if (true === this.args.focused) {
        this.quill.focus();
      }

      if (this.args.delta) {
        this.value = this.quill.setContents(this.args.delta);
        this.doText();
      } else if (this.args.text) {
        this.value = this.quill.setText(this.args.text);
        this.doChange();
      } else {
        this.value = this.quill.getContents();
      } // emit the length and words on start-up


      this.doLength();
      this.doWords();
      /** Native Quill Events */

      this.quill.on('text-change', this.doTextChange);
      this.quill.on('selection-change', this.doSelectionChange);
      this.quill.on('editor-change', this.doEditorChange);
      /** Custom Events */

      this.quill.on('text-change', this.doLength);
      this.quill.on('text-change', this.doWords);
      this.quill.on('text-change', this.doChange);
      this.quill.on('text-change', this.doText);
      this.quillService.register(this.name, this.quill);
    }

    updateQuill(el, args, _ref2) {
      let {
        enabled,
        delta
      } = _ref2;

      if (this.quill) {
        this.quill.enable(enabled ?? true);

        if (delta !== this.value) {
          this.value = this.quill.setContents(delta, _quill.default.sources.SILENT);
        }
      }
    }

    doTextChange(delta, oldDelta, source) {
      if (this.args.onTextChange) {
        (0, _runloop.schedule)('actions', this, this._handleEvent, 'onTextChange', delta, oldDelta, source);
      }
    }

    doSelectionChange(range, oldRange, source) {
      if (this.args.onSelectionChange) {
        (0, _runloop.schedule)('actions', this, this._handleEvent, 'onSelectionChange', range, oldRange, source);
      }
    }

    doEditorChange(eventName) {
      if (this.args.onEditorChange) {
        for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
          args[_key - 1] = arguments[_key];
        }

        (0, _runloop.schedule)('actions', this, this._handleEvent, 'onEditorChange', eventName, ...args);
      }
    }

    doLength() {
      if (this.quill && this.args.onLength) {
        (0, _runloop.schedule)('actions', this, this._handleEvent, 'onLength', this.quill.getLength());
      }
    }

    doChange() {
      if (this.quill && this.args.onChange) {
        this.value = this.quill.getContents();
        (0, _runloop.schedule)('actions', this, this._handleEvent, 'onChange', this.value);
      }
    }

    doText() {
      if (this.quill && this.args.onText) {
        (0, _runloop.schedule)('actions', this, this._handleEvent, 'onText', this.quill.getText());
      }
    }

    doWords() {
      if (this.quill && this.args.onWords) {
        let text = this.quill.getText();
        (0, _runloop.schedule)('actions', this, this._handleEvent, 'onWords', this._calculateWords(text));
      }
    }

    willDestroy() {
      super.willDestroy(...arguments);
      /** Native Quill Events */

      this.quill.off('text-change', this.doTextChange);
      this.quill.off('selection-change', this.doSelectionChange);
      this.quill.off('editor-change', this.doEditorChange);
      /** Custom Events */

      this.quill.off('text-change', this.doLength);
      this.quill.off('text-change', this.doWords);
      this.quill.off('text-change', this.doChange);
      this.quill.off('text-change', this.onText);
      this.quill = null;
      this.value = null;
      this.quillService.deregister(this.name);
    }

    _handleEvent(callback) {
      if (!this.isDestroying && !this.isDestroyed && this.args[callback]) {
        for (var _len2 = arguments.length, args = new Array(_len2 > 1 ? _len2 - 1 : 0), _key2 = 1; _key2 < _len2; _key2++) {
          args[_key2 - 1] = arguments[_key2];
        }

        this.args[callback](...args);
      }
    }

    _calculateWords(text) {
      text = text.trim();
      return text.length > 0 ? text.split(/\s+/).length : 0;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "quillService", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "initQuill", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "initQuill"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateQuill", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "updateQuill"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "doTextChange", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "doTextChange"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "doSelectionChange", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "doSelectionChange"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "doEditorChange", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "doEditorChange"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "doLength", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "doLength"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "doChange", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "doChange"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "doText", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "doText"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "doWords", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "doWords"), _class.prototype)), _class));
  _exports.default = QuillEditorComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, QuillEditorComponent);
});