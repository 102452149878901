define("ember-svg-jar/inlined/pride/icon.my-festival", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"none\"><path fill=\"#383838\" d=\"M19.168 17.265v-2.9h-.813v3.713h2.355v-.813h-1.542zm0 0v-2.9h-.813v3.713h2.355v-.813h-1.542zm-.406-5.084a5.243 5.243 0 00-5.239 5.238 5.246 5.246 0 005.239 5.238A5.246 5.246 0 0024 17.42a5.243 5.243 0 00-5.238-5.238zm0 9.663a4.43 4.43 0 01-4.425-4.425 4.43 4.43 0 014.425-4.425 4.43 4.43 0 014.425 4.425 4.43 4.43 0 01-4.425 4.425zm.406-7.48h-.813v3.714h2.355v-.813h-1.541v-2.9zM6.947 15.769v.813H9.44v-.813H6.947zm-4.153 0v.813h2.493v-.813H2.794zm4.152-3.835v.813H9.44v-.813H6.947zm4.153 0v.813h2.493v-.813H11.1zm-8.305 0v.813h2.493v-.813H2.794zm0-3.832v.814h2.493v-.813H2.794zm4.152 0v.814H9.44v-.813H6.947zm4.153 0v.814h2.493v-.813H11.1zm4.153 0v.814h2.493v-.813h-2.493zM14.95 0v3.356h.814V0h-.813zM9.864 0v3.356h.813V0h-.813zM4.775 0v3.356h.813V0h-.813zM6.75.91v.814H8.7V.911H6.752zm5.088 0v.814h1.953V.911h-1.953zm7.073 0h-1.985v.814h1.985c.447 0 .813.366.813.814v2.7H.813v-2.7c0-.448.366-.814.814-.814h1.985V.911H1.627A1.63 1.63 0 000 2.538v15.247c0 .899.732 1.627 1.627 1.627H13.05a5.82 5.82 0 01-.223-.813h-11.2a.813.813 0 01-.814-.814V6.051h18.912v5.393c.277.045.55.11.814.191V2.539c0-.895-.728-1.627-1.627-1.627z\"/><path fill=\"#9FF1DF\" d=\"M18.762 12.994a4.43 4.43 0 00-4.425 4.425 4.43 4.43 0 004.425 4.425 4.43 4.43 0 004.425-4.425 4.43 4.43 0 00-4.425-4.425zm1.948 5.084h-2.355v-3.713h.813v2.9h1.541v.813z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 22.657"
    }
  };
  _exports.default = _default;
});