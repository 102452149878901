define("@cloudcreativity/ember-quill/components/quill/toolbar/button-or-select", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if (or (is-array @values) (eq "select" @controlType))}}
    {{#if (has-block)}}
      <Quill::Toolbar::Select @type={{@type}} ...attributes>
        {{yield}}
      </Quill::Toolbar::Select>
    {{else}}
      <Quill::Toolbar::Select
        @selected={{@selected}}
        @type={{@type}}
        @values={{@values}}
        ...attributes
      />
    {{/if}}
  {{else}}
    {{#if (has-block)}}
      <Quill::Toolbar::Button @type={{@type}} ...attributes>
        {{yield}}
      </Quill::Toolbar::Button>
    {{else}}
      <Quill::Toolbar::Button @type={{@type}} ...attributes />
    {{/if}}
  {{/if}}
  
  */
  {
    "id": "QI8HHzao",
    "block": "[[[41,[28,[37,1],[[28,[37,2],[[30,1]],null],[28,[37,3],[\"select\",[30,2]],null]],null],[[[41,[48,[30,6]],[[[1,\"    \"],[8,[39,5],[[17,3]],[[\"@type\"],[[30,4]]],[[\"default\"],[[[[1,\"\\n      \"],[18,6,null],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]],[[[1,\"    \"],[8,[39,5],[[17,3]],[[\"@selected\",\"@type\",\"@values\"],[[30,5],[30,4],[30,1]]],null],[1,\"\\n\"]],[]]]],[]],[[[41,[48,[30,6]],[[[1,\"    \"],[8,[39,7],[[17,3]],[[\"@type\"],[[30,4]]],[[\"default\"],[[[[1,\"\\n      \"],[18,6,null],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]],[[[1,\"    \"],[8,[39,7],[[17,3]],[[\"@type\"],[[30,4]]],null],[1,\"\\n\"]],[]]]],[]]]],[\"@values\",\"@controlType\",\"&attrs\",\"@type\",\"@selected\",\"&default\"],false,[\"if\",\"or\",\"is-array\",\"eq\",\"has-block\",\"quill/toolbar/select\",\"yield\",\"quill/toolbar/button\"]]",
    "moduleName": "@cloudcreativity/ember-quill/components/quill/toolbar/button-or-select.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});