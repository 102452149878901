define("ember-svg-jar/inlined/icons/app/app.family", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path opacity=\".5\" d=\"M29.487 11.468a4.001 4.001 0 110 8.003 4.001 4.001 0 010-8.003m0 9.811a5.814 5.814 0 10-.01-11.628 5.814 5.814 0 00.01 11.628m-15.631-3.703a3.703 3.703 0 110 7.407 3.703 3.703 0 010-7.407m0 9.08a5.381 5.381 0 10-.008-10.762 5.381 5.381 0 00.008 10.762m27.937-3.423a2.749 2.749 0 110 5.497 2.749 2.749 0 010-5.497m0 6.74a3.995 3.995 0 10-.006-7.989 3.995 3.995 0 00.006 7.99\"/><path d=\"M24.91 10.005c-.91-1.155-2.692-1.741-5.3-1.741-1.822 0-3.236.294-4.244.865V7.485c.056-.478.252-2.984-1.44-4.946C12.609 1.008 10.535.232 7.764.232c-2.758 0-4.833.775-6.167 2.305C-.138 4.525.04 7.08.09 7.546v21.909a.906.906 0 001.812 0V7.494a.852.852 0 00-.008-.123c-.003-.02-.25-2.132 1.069-3.642.975-1.118 2.59-1.684 4.8-1.684 2.212 0 3.82.56 4.78 1.664 1.298 1.492 1.024 3.568 1.022 3.584a.882.882 0 00-.01.135v20.99a.907.907 0 001.812 0v-5.832a.894.894 0 00.113-.43V12.084a.898.898 0 00-.02-.197c-.001-.004-.053-.37.25-.747.319-.398 1.25-1.065 3.9-1.065 2.61 0 3.534.64 3.852 1.02.307.369.253.728.251.744a.89.89 0 00-.025.214v10.101a.906.906 0 101.812 0V12.133c.052-.345.1-1.25-.59-2.128\" mask=\"url(#b)\" transform=\"translate(21.751 22.81)\"/><path d=\"M13.878 28.103c-2.37 0-4.153.666-5.296 1.98-1.446 1.661-1.314 3.77-1.27 4.197v13.44a.906.906 0 101.813 0V34.23a.973.973 0 00-.008-.12c-.002-.017-.2-1.664.844-2.849.784-.892 2.103-1.345 3.917-1.345 1.823 0 3.138.45 3.909 1.338 1.01 1.163.798 2.773.797 2.785a.91.91 0 00-.01.136v18.09a.906.906 0 001.812 0V34.23c.05-.436.196-2.505-1.213-4.145-1.13-1.315-2.91-1.982-5.295-1.982\"/><path d=\"M8.47 42.594c-4.118-4.301-6.657-10.128-6.657-16.54 0-13.201 10.74-23.94 23.94-23.94 13.2 0 23.94 10.739 23.94 23.94 0 4.49-1.245 8.693-3.404 12.288h.14v3.045a25.618 25.618 0 005.077-15.334c0-14.2-11.553-25.752-25.753-25.752S0 11.853 0 26.053C0 33.61 3.27 40.414 8.47 45.13v-2.535z\" mask=\"url(#d)\" transform=\"translate(0 .152)\"/>",
    "attrs": {
      "fill": "currentColor",
      "viewBox": "0 0 52 54"
    }
  };
  _exports.default = _default;
});