define("ember-svg-jar/inlined/icons/app/app.content-warning", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M541.1.2C242.9.2 1.1 242 1.1 540.2s241.8 540 540 540 540-241.8 540-540S839.3.2 541.1.2zM330 648.9c25.5 0 53.2-9.2 72.8-26.1 3.8-3.3 10.9-3.3 14.1.5l42.4 45.1c3.8 3.8 3.3 10.9-.5 14.7-36.9 35.9-83.7 52.7-132 52.7-108.7 0-196.2-86.4-196.2-195.1S218 344.6 326.7 344.6c54.3 0 94.6 16.8 131.5 50.5 4.9 4.3 4.9 10.9.5 15.2l-42.4 44c-3.8 3.8-9.8 3.8-13.6 0-20.1-17.9-46.7-27.7-73.4-27.7-61.4 0-106.5 51.1-106.5 111.9.1 60.4 45.8 110.4 107.2 110.4zm518.3 79.4c-1.1 4.4-5.4 7.6-9.8 7.6h-8.7c-3.8 0-7.6-2.7-9.2-6l-99.4-222.3h-1.6l-98.9 222.3c-1.6 3.3-5.4 6-9.2 6h-8.7c-4.3 0-8.7-3.3-9.8-7.6L490.2 363.1c-2.2-7.6 2.2-13 9.8-13h63.6c4.3 0 8.7 3.8 9.8 7.6l46.7 184.2h2.2l84.2-191.3c1.6-3.3 4.9-6 9.2-6h9.8c4.9 0 8.2 2.7 9.2 6L819 541.9h2.2l46.7-184.2c1.1-3.8 5.4-7.6 9.8-7.6h63.6c7.6 0 12 5.4 9.8 13L848.3 728.3z\"/>",
    "attrs": {
      "viewBox": "0 0 1080 1080",
      "fill": "currentColor"
    }
  };
  _exports.default = _default;
});