define("@cloudcreativity/ember-quill/components/quill/toolbar", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div ...attributes id={{@id}}>
    {{yield (hash
      align=(component "quill/toolbar/button-or-select" type="align")
      background=(component "quill/toolbar/select" type="background")
      blockquote=(component "quill/toolbar/button" type="blockquote")
      bold=(component "quill/toolbar/button" type="bold")
      clean=(component "quill/toolbar/button" type="clean")
      code-block=(component "quill/toolbar/button" type="code-block")
      code=(component "quill/toolbar/button" type="code")
      color=(component "quill/toolbar/select" type="color")
      direction=(component "quill/toolbar/button" type="direction")
      font=(component "quill/toolbar/select" type="font")
      formula=(component "quill/toolbar/button" type="formula")
      group=(component "quill/toolbar/group")
      header=(component "quill/toolbar/button-or-select" type="header")
      image=(component "quill/toolbar/button" type="image")
      indent=(component "quill/toolbar/button" type="indent")
      italic=(component "quill/toolbar/button" type="italic")
      link=(component "quill/toolbar/button" type="link")
      list=(component "quill/toolbar/button" type="list")
      script=(component "quill/toolbar/button" type="script")
      size=(component "quill/toolbar/select" type="size")
      strike=(component "quill/toolbar/button" type="strike")
      underline=(component "quill/toolbar/button" type="underline")
      video=(component "quill/toolbar/button" type="video")
    )}}
  </div>
  
  */
  {
    "id": "v4MGnx1+",
    "block": "[[[11,0],[17,1],[16,1,[30,2]],[12],[1,\"\\n  \"],[18,3,[[28,[37,1],null,[[\"align\",\"background\",\"blockquote\",\"bold\",\"clean\",\"code-block\",\"code\",\"color\",\"direction\",\"font\",\"formula\",\"group\",\"header\",\"image\",\"indent\",\"italic\",\"link\",\"list\",\"script\",\"size\",\"strike\",\"underline\",\"video\"],[[50,\"quill/toolbar/button-or-select\",0,null,[[\"type\"],[\"align\"]]],[50,\"quill/toolbar/select\",0,null,[[\"type\"],[\"background\"]]],[50,\"quill/toolbar/button\",0,null,[[\"type\"],[\"blockquote\"]]],[50,\"quill/toolbar/button\",0,null,[[\"type\"],[\"bold\"]]],[50,\"quill/toolbar/button\",0,null,[[\"type\"],[\"clean\"]]],[50,\"quill/toolbar/button\",0,null,[[\"type\"],[\"code-block\"]]],[50,\"quill/toolbar/button\",0,null,[[\"type\"],[\"code\"]]],[50,\"quill/toolbar/select\",0,null,[[\"type\"],[\"color\"]]],[50,\"quill/toolbar/button\",0,null,[[\"type\"],[\"direction\"]]],[50,\"quill/toolbar/select\",0,null,[[\"type\"],[\"font\"]]],[50,\"quill/toolbar/button\",0,null,[[\"type\"],[\"formula\"]]],[50,\"quill/toolbar/group\",0,null,null],[50,\"quill/toolbar/button-or-select\",0,null,[[\"type\"],[\"header\"]]],[50,\"quill/toolbar/button\",0,null,[[\"type\"],[\"image\"]]],[50,\"quill/toolbar/button\",0,null,[[\"type\"],[\"indent\"]]],[50,\"quill/toolbar/button\",0,null,[[\"type\"],[\"italic\"]]],[50,\"quill/toolbar/button\",0,null,[[\"type\"],[\"link\"]]],[50,\"quill/toolbar/button\",0,null,[[\"type\"],[\"list\"]]],[50,\"quill/toolbar/button\",0,null,[[\"type\"],[\"script\"]]],[50,\"quill/toolbar/select\",0,null,[[\"type\"],[\"size\"]]],[50,\"quill/toolbar/button\",0,null,[[\"type\"],[\"strike\"]]],[50,\"quill/toolbar/button\",0,null,[[\"type\"],[\"underline\"]]],[50,\"quill/toolbar/button\",0,null,[[\"type\"],[\"video\"]]]]]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&attrs\",\"@id\",\"&default\"],false,[\"yield\",\"hash\",\"component\"]]",
    "moduleName": "@cloudcreativity/ember-quill/components/quill/toolbar.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});