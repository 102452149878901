define("ember-svg-jar/inlined/icons/app/app.on-the-move", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M739.6 472.9c-20.6 70-55.8 135.7-86 206.9-90.2-22.3-176.6-43.6-260.4-64.4-4-59.9-7.3-113.8-11.3-167.7-7.1-96.2 19.7-182.9 79.8-257.1 58.1-71.8 141.3-75.5 210.3-8.7 83.7 81.3 99.1 183.9 67.6 291M504.8 925.1c-58.4 10.7-114.2-1.7-154.3-47.8-38.1-43.8-26.3-94.5-7.9-147.8C438 753.3 529 776 622.3 799.3c-20.8 62.9-50.5 113.6-117.5 125.8M540 0C241.8 0 0 241.8 0 540s241.8 540 540 540 540-241.7 540-540S838.2 0 540 0\"/>",
    "attrs": {
      "viewBox": "0 0 1080 1080",
      "fill": "currentColor"
    }
  };
  _exports.default = _default;
});