define("ember-svg-jar/inlined/icons/app/app.great-for-kids", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path class=\"st0\" d=\"M698.7 598.9c-32.5 0-59-26.4-59-59s26.4-59 59-59c32.5 0 59 26.4 59 59s-26.4 59-59 59M540 824.4c-119.5 0-158.7-82.2-158.7-82.2h317.4s-39.2 82.2-158.7 82.2M322.3 539.9c0-32.5 26.4-59 59-59s59 26.4 59 59-26.4 59-59 59-59-26.4-59-59m477.4-225.1s-2.6 58.3-323.8 38c-134.7-8.6-218.4 17-270.5 49.6-17.5 42.5-27.3 88.9-27.3 137.7 0 199.6 162.3 362 361.9 362s361.9-162.4 361.9-362c0-34.6-5-68-14-99.7-69.7-30.5-88.2-125.6-88.2-125.6\"/><path class=\"st0\" d=\"M540 1016.5c-262.8 0-476.6-213.8-476.6-476.6S277.2 63.3 540 63.3s476.6 213.8 476.6 476.6-213.8 476.6-476.6 476.6M540 0C241.8 0 0 241.8 0 540s241.8 540 540 540 540-241.7 540-540S838.2 0 540 0\"/>",
    "attrs": {
      "viewBox": "0 0 1080 1080",
      "fill": "currentColor"
    }
  };
  _exports.default = _default;
});