define("ember-svg-jar/inlined/pride/menu.lineup", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"none\"><path fill=\"#FFF\" d=\"M5.2 0A5.206 5.206 0 000 5.2a5.207 5.207 0 003.14 4.774v8.928c0 .77.428 1.443 1.061 1.797v.778c0 .445.294.819.696.947a3.66 3.66 0 01-.402 1.31c-.545 1.012-1.526 1.525-2.912 1.525v.571c2.008 0 2.966-.992 3.414-1.825.337-.622.44-1.24.473-1.569.417-.12.728-.502.728-.959V20.7a2.055 2.055 0 001.058-1.797V9.975A5.213 5.213 0 0010.399 5.2C10.4 2.333 8.066 0 5.2 0zm0 .57c2.435 0 4.435 1.897 4.612 4.293-.177 1.634-2.176 2.93-4.612 2.93-2.439 0-4.438-1.295-4.613-2.93A4.633 4.633 0 015.2.57zm.427 20.908a.427.427 0 11-.855 0v-.565c.137.029.282.046.428.046.148 0 .29-.017.427-.046v.565zm1.059-2.576a1.488 1.488 0 01-2.975 0V10.18a5.142 5.142 0 002.975 0v8.722zM5.2 9.83A4.634 4.634 0 01.787 6.597c.919 1.058 2.55 1.766 4.413 1.766 1.862 0 3.493-.71 4.412-1.769A4.636 4.636 0 015.2 9.83z\"/><path fill=\"#F366BA\" d=\"M6.686 10.18v8.722a1.488 1.488 0 01-2.975 0V10.18a5.142 5.142 0 002.975 0zm3.126-5.317c-.177 1.635-2.176 2.93-4.612 2.93-2.439 0-4.438-1.295-4.613-2.93A4.633 4.633 0 015.2.57c2.435 0 4.435 1.897 4.612 4.293z\"/><path fill=\"#FFF\" d=\"M20.895 8.524a.283.283 0 00-.236-.059l-7.034 1.426a.285.285 0 00-.23.28v7.566a1.591 1.591 0 00-1.027-.376c-.88 0-1.598.717-1.598 1.598s.717 1.597 1.598 1.597a1.6 1.6 0 001.598-1.597v-6.523l6.464-1.31v4.24a1.591 1.591 0 00-1.028-.376c-.88 0-1.598.716-1.598 1.597s.717 1.598 1.598 1.598A1.6 1.6 0 0021 16.587V8.744a.286.286 0 00-.105-.22zm-8.527 11.462a1.029 1.029 0 010-2.055 1.029 1.029 0 010 2.055zm7.034-2.37a1.029 1.029 0 010-2.056 1.029 1.029 0 010 2.055zm1.028-7.073l-6.464 1.31v-1.45l6.464-1.31v1.45z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "21",
      "height": "25.83"
    }
  };
  _exports.default = _default;
});