define("ember-svg-jar/inlined/dot", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<circle cx=\"4\" cy=\"4\" r=\"3\"/>",
    "attrs": {
      "class": "-ml-0.5 mr-1.5 h-2 w-2 text-indigo-400",
      "fill": "currentColor",
      "viewBox": "0 0 8 8"
    }
  };
  _exports.default = _default;
});