define("ember-svg-jar/inlined/icons/app/app.music-note", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"currentColor\" d=\"M16.394 2.885l-3.407-1.693a1.842 1.842 0 00-1.784.08c-.54.333-.87.92-.87 1.551v9.574A5.463 5.463 0 008.5 12.06c-3.033 0-5.5 2.453-5.5 5.47C3 20.545 5.467 23 8.5 23s5.5-2.454 5.5-5.47V5.771l.754.376c1.797.894 2.913 2.812 2.913 4.81a5.192 5.192 0 01-1.544 3.707 1.815 1.815 0 000 2.578 1.84 1.84 0 002.593 0 8.803 8.803 0 002.617-6.285c0-3.389-1.892-6.556-4.94-8.072z\"/>",
    "attrs": {
      "viewBox": "0 0 24 24"
    }
  };
  _exports.default = _default;
});