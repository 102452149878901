define("ember-svg-jar/inlined/icons/app/app.seated", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path class=\"st0\" d=\"M384 593.2c103.7.4 207.3.4 311 0 31.2-.1 56.4-23.1 58.8-52.2H326.4c2.5 28.8 27.5 52.1 57.6 52.2zm39.8-121.4h233.4c.2-3.4.4-6.3.4-9.2 0-63.4.1-126.8 0-190.2 0-34.6-21.5-56.4-56.1-56.6-40.6-.2-81.1-.2-121.7 0-35.1.1-56.6 21.8-56.6 57V463c.1 2.8.4 5.7.6 8.8z\"/><path class=\"st0\" d=\"M540.5.2C242.3.2.5 242 .5 540.2s241.8 540 540 540 540-241.8 540-540S838.7.2 540.5.2zm317.1 433.5c-10.7.7-21.6.1-33.8.1v96.9c0 2.6-.5 5-.6 7.6v322.1c0 18.4-14.9 33.3-33.3 33.3s-33.3-14.9-33.3-33.3V645.3c-11.3 6.1-23.4 11.1-36.8 13.8-9.6 2-19.7 2.5-29.5 2.6-38.1.3-76.3.1-115.1.1H387.6c-23.3 0-44.9-5.5-64.6-16.8v215.4c0 18.4-14.9 33.3-33.3 33.3s-33.3-14.9-33.3-33.3V509.8c0-1 .2-1.9.3-2.8-.1-20.6.1-41.3.1-61.9v-11.2c-10.9 0-21 .3-31-.1-23-.7-37.9-15.4-37.1-36.3.6-15.3 12.2-28.8 27.4-31.3 5-.8 10.2-1.1 15.2-1.1 27.3 0 54.7-.2 82 .6 14 .4 26.6 11.1 29.5 23.8 3.3 14.1.6 27.3-11.5 36.4-4.8 3.6-6.1 7.2-5.9 12.7.3 11 .1 22.1.1 33.6h29.2v-10.1c0-64-.2-128.1.1-192.1.2-59.4 38-106.3 95.7-119.1 8.1-1.8 16.7-2.6 25-2.7 43.3-.3 86.6-.4 129.8 0 58.6.5 105.7 39 117.9 96.1 2 9.3 2.6 19.1 2.6 28.6.2 62.5.1 125.1.1 187.6V472H755c0-12.8.2-25.8-.2-38.9 0-1.7-2.8-3.6-4.6-4.9-13-9.5-18.4-23.1-14.7-37.7 3.4-13.5 15.1-24 30.9-24.5 30.6-.9 61.3-1.3 91.9-.3 20.8.7 33.7 15 33.7 33.7.1 18.9-13.6 32.9-34.4 34.3z\"/>",
    "attrs": {
      "viewBox": "0 0 1080 1080",
      "fill": "currentColor"
    }
  };
  _exports.default = _default;
});