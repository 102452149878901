define("ember-svg-jar/inlined/icons/app/app.home", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M629.14 1077.87H123.35c-24.85 0-45-20.15-45-45V47.12c0-18.2 10.96-34.61 27.78-41.57 7.67-3.18 15.87-4.1 23.73-2.95 40.82 2.17 514.96.92 827.67-.47h.2a44.997 44.997 0 0145 45v321.19c0 24.85-20.15 45-45 45H832.9v294.66c0 24.85-20.15 45-45 45H674.15v279.89c0 24.85-20.15 45-45 45zm-460.79-90h415.79V707.98c0-24.85 20.15-45 45-45h113.75V368.32c0-24.85 20.15-45 45-45h124.83V92.31c-72.63.3-222.24.87-370.7 1.2-120.91.27-217.42.33-286.84.17-36.23-.08-65.37-.22-86.83-.41v894.6zM155.12 15.26l.05.05-.05-.05zm-.06-.05l.05.05-.05-.05zm-.05-.05l.05.05-.05-.05zm-.87-.84c.29.27.58.55.87.84-.29-.29-.58-.56-.87-.84zm-.05-.05l.05.04-.05-.04zm-.05-.05l.04.04-.04-.04zm-.05-.04l.03.03-.03-.03zm-.05-.05l.03.03-.03-.03zm-.04-.03zm-.06-.05z\" fill-opacity=\".4\"/>",
    "attrs": {
      "viewBox": "0 0 1080 1080",
      "fill": "currentColor"
    }
  };
  _exports.default = _default;
});