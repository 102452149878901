define("ember-svg-jar/inlined/icon.stalls", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<defs><style>.cls-1{fill:none;stroke:#fff;stroke-miterlimit:10;stroke-width:25px}</style></defs><rect width=\"400\" height=\"400\" x=\".25\" rx=\"94.02\" ry=\"94.02\"/><rect width=\"237.75\" height=\"197.1\" x=\"81.37\" y=\"132.21\" class=\"cls-1\" rx=\"16.24\" ry=\"16.24\"/><path d=\"M141.28 133.89v-29.62c0-23.37 18.94-42.31 42.31-42.31h33.3c23.37 0 42.31 18.94 42.31 42.31v29.62\" class=\"cls-1\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "id": "Layer_1",
      "data-name": "Layer 1",
      "viewBox": "0 0 400 400"
    }
  };
  _exports.default = _default;
});