define("ember-svg-jar/inlined/icons/app/shape.tag", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"currentColor\" d=\"M0 0h16L3.998 11.93 16 24H0z\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "viewBox": "0 0 16 24",
      "fill": "currentColor"
    }
  };
  _exports.default = _default;
});