define("ember-svg-jar/inlined/icon.graffiti", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<defs><style>.cls-1{fill:#fff}</style></defs><rect width=\"400\" height=\"400\" rx=\"94.02\" ry=\"94.02\"/><path class=\"cls-1\" d=\"M213.62 367.4H110.63c-7.72 0-15.21-3.2-20.54-8.78s-8.19-13.2-7.85-20.91l8.53-189.83c.68-15.22 13.15-27.14 28.39-27.14h85.93c15.23 0 27.7 11.92 28.39 27.14l8.53 189.83c.35 7.71-2.51 15.33-7.85 20.91s-12.82 8.78-20.54 8.78zm-94.46-222.67c-2.37 0-4.3 1.85-4.41 4.22l-8.53 189.83c-.07 1.64.73 2.74 1.22 3.25s1.55 1.36 3.19 1.36h102.99c1.64 0 2.71-.85 3.19-1.36s1.29-1.61 1.22-3.25l-8.53-189.83a4.406 4.406 0 00-4.41-4.22h-85.93zM169.09 113.27h-13.93c-13.23 0-24-10.77-24-24V75.34c0-13.23 10.77-24 24-24h13.93c13.23 0 24 10.77 24 24v13.93c0 13.23-10.77 24-24 24zm-13.93-37.93v13.93h13.93V75.34h-13.93zM329.45 133.41c-1.28 0-2.56-.2-3.81-.62L208.78 93.68c-4.89-1.64-8.19-6.22-8.19-11.38s3.3-9.74 8.19-11.38l116.86-39.11a12 12 0 0115.81 11.38v78.22c0 3.86-1.86 7.48-4.99 9.74a12.001 12.001 0 01-7.01 2.26zM250.4 82.3l67.05 22.44V59.86L250.4 82.3z\"/>",
    "attrs": {
      "id": "Layer_1",
      "data-name": "Layer 1",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 400 400"
    }
  };
  _exports.default = _default;
});