define("ember-svg-jar/inlined/icons/app/app.whats-on", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M60 3a56.83 56.83 0 1056.83 56.83A56.89 56.89 0 0060 3zm0 109.7a52.83 52.83 0 1152.83-52.83A52.89 52.89 0 0160 112.7z\"/><circle cx=\"31.45\" cy=\"33.64\" r=\"5\"/><circle cx=\"31.45\" cy=\"46.75\" r=\"5\"/><circle cx=\"31.45\" cy=\"59.87\" r=\"5\"/><circle cx=\"31.45\" cy=\"72.99\" r=\"5\"/><circle cx=\"31.45\" cy=\"86.1\" r=\"5\"/><path d=\"M41.14 35.64h38.51a2 2 0 000-4H41.14a2 2 0 000 4zm51.63 9.11H41.14a2 2 0 000 4h51.63a2 2 0 000-4zM41.14 61.87h43.54a2 2 0 000-4H41.14a2 2 0 000 4zM92.77 71H41.14a2 2 0 000 4h51.63a2 2 0 000-4zM67 84.1H41.14a2 2 0 000 4H67a2 2 0 100-4z\" fill-opacity=\".5\"/>",
    "attrs": {
      "viewBox": "0 0 120 120",
      "fill": "currentColor"
    }
  };
  _exports.default = _default;
});