define("ember-svg-jar/inlined/icons/app/app.free", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<defs><path id=\"a\" d=\"M0 0h113.661v113.661H0z\"/></defs><g fill=\"none\" fill-rule=\"evenodd\" transform=\"translate(3 3)\"><mask id=\"b\" fill=\"#fff\"><use xlink:href=\"#a\"/></mask><path fill=\"currentColor\" d=\"M56.831 109.661C27.7 109.661 4 85.962 4 56.83 4 27.7 27.7 4 56.831 4c29.131 0 52.83 23.7 52.83 52.83 0 29.132-23.699 52.831-52.83 52.831M56.831 0C25.494 0 0 25.494 0 56.83c0 31.337 25.494 56.831 56.831 56.831 31.336 0 56.83-25.494 56.83-56.831C113.661 25.494 88.167 0 56.831 0\" mask=\"url(#b)\"/><path fill=\"currentColor\" fill-opacity=\".5\" d=\"M19.411 65.361V48.485c0-2.043 1.122-3.165 3.198-3.165h10.229c1.273 0 2.194.938 2.194 2.244 0 1.289-.921 2.193-2.194 2.193h-7.885v5.492h7.032c1.322 0 2.193.87 2.193 2.159 0 1.29-.887 2.16-2.193 2.16h-7.032v5.793c0 1.909-.971 2.98-2.713 2.98-1.791 0-2.829-1.105-2.829-2.98m27.374-9.225c2.193 0 3.533-1.155 3.533-3.231 0-2.043-1.323-3.198-3.533-3.198h-3.6v6.429h3.6zM40.456 68.34c-1.791 0-2.812-1.088-2.812-2.98V49.32c0-2.711 1.222-4.001 3.85-4.001h6.044c5.124 0 8.271 2.696 8.271 7.082 0 2.88-1.406 5.14-3.884 6.296l2.98 5.273a3.56 3.56 0 01.452 1.742c0 1.523-1.138 2.595-2.746 2.595-1.456 0-2.226-.603-3.13-2.378l-3.014-5.876h-3.282v5.307c0 1.909-.971 2.98-2.729 2.98zm18.852-19.856c0-2.043 1.122-3.164 3.215-3.164h10.363c1.273 0 2.177.904 2.177 2.21 0 1.289-.904 2.176-2.177 2.176h-8.103v4.939h7.701c1.19 0 1.993.837 1.993 2.043 0 1.222-.804 2.009-1.993 2.009h-7.7v5.09h8.035c1.34 0 2.244.87 2.244 2.193 0 1.339-.904 2.193-2.26 2.193h-10.28c-2.093 0-3.215-1.106-3.215-3.165V48.486zm19.187 0c0-2.043 1.122-3.164 3.215-3.164h10.363c1.273 0 2.177.904 2.177 2.21 0 1.289-.904 2.176-2.177 2.176H83.97v4.939h7.701c1.19 0 1.993.837 1.993 2.043 0 1.222-.804 2.009-1.993 2.009h-7.7v5.09h8.035c1.34 0 2.244.87 2.244 2.193 0 1.339-.904 2.193-2.26 2.193H81.71c-2.093 0-3.215-1.106-3.215-3.165V48.486z\"/></g>",
    "attrs": {
      "xmlns:xlink": "http://www.w3.org/1999/xlink",
      "viewBox": "0 0 120 120"
    }
  };
  _exports.default = _default;
});