define("ember-svg-jar/inlined/freedom/icon.app", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"#2abdef\" d=\"M1 1h1022v1022H1z\"/><path fill=\"#12b2dd\" d=\"M259 801l221.7 221.7H1024v-511L738.4 225.1z\"/><path d=\"M259.2 801h288V609h91.6V417h100.4V225h-480s-.6 576.6 0 576z\" fill=\"#fff\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 1024 1024"
    }
  };
  _exports.default = _default;
});