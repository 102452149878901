define("ember-svg-jar/inlined/icon.taxi", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M710.2 610.82c-30.51 0-55.24 24.73-55.24 55.24s24.73 55.24 55.24 55.24 55.24-24.73 55.24-55.24-24.73-55.24-55.24-55.24zM454.24 745.99h174.54c16.29 0 29.51 13.22 29.51 29.51v7.53c0 16.28-13.22 29.5-29.5 29.5H454.25c-16.28 0-29.5-13.22-29.5-29.5v-7.52c0-16.29 13.22-29.51 29.51-29.51zM737.04 284.01c-1-8.07-7.89-14.17-16.03-14.17h-359c-8.14 0-15.03 6.09-16.03 14.17L317.7 512.46c-.08.66-.12 1.32-.12 1.98h447.86c0-.66-.04-1.33-.12-1.98l-28.28-228.45z\"/><path d=\"M752.95 1.08H330.07C149.61 1.08 3.32 147.37 3.32 327.83v422.88c0 180.46 146.29 326.75 326.75 326.75h422.88c180.46 0 326.75-146.29 326.75-326.75V327.83c0-180.46-146.29-326.75-326.75-326.75zM236.86 689.5c-21.26 0-38.5-17.24-38.5-38.5v-90.39c0-21.27 17.24-38.5 38.5-38.5V689.5zm599.26 77.21c0 38.78-25.59 71.23-60.68 82.38v37.53c0 18.02-14.6 32.62-32.62 32.62s-32.62-14.6-32.62-32.62v-33.08H369.93v33.08c0 18.02-14.6 32.62-32.62 32.62s-32.62-14.6-32.62-32.62v-38.43c-33.6-11.99-57.79-43.77-57.79-81.49V514.44c0-3.56.22-7.13.66-10.67l28.28-228.44c5.38-43.5 42.34-76.17 86.17-76.17h45.29c13.47-35.25 47.36-60.39 87.35-60.39h93.74c39.98 0 73.87 25.14 87.35 60.39h45.29c43.83 0 80.79 32.67 86.17 76.17l28.28 228.44c.44 3.54.66 7.1.66 10.67V766.7zM884.66 651c0 21.26-17.24 38.5-38.5 38.5V522.11c21.26 0 38.5 17.24 38.5 38.5V651z\"/><path d=\"M350.69 610.82c-30.51 0-55.24 24.73-55.24 55.24s24.73 55.24 55.24 55.24 55.24-24.73 55.24-55.24-24.73-55.24-55.24-55.24z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "500",
      "height": "500",
      "viewBox": "0 0 1080 1080"
    }
  };
  _exports.default = _default;
});