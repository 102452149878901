define("ember-svg-jar/inlined/image", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M18 15.667H2L7.333 5l4 8L14 7.667z\" fill=\"currentColor\" fill-rule=\"nonzero\"/>",
    "attrs": {
      "viewBox": "0 0 20 20"
    }
  };
  _exports.default = _default;
});