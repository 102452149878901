define("ember-svg-jar/inlined/icon.face-paint", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<defs><style>.cls-1,.cls-2{fill:#fff}.cls-2{fill-rule:evenodd}</style></defs><rect width=\"400\" height=\"400\" rx=\"94.02\" ry=\"94.02\"/><path class=\"cls-1\" d=\"M168.45 188.67c7.82 0 14.16-6.34 14.16-14.16v-7.08c0-19.55-15.85-35.4-35.4-35.4s-35.4 15.85-35.4 35.4v7.08c0 7.82 6.34 14.16 14.16 14.16s14.16-6.34 14.16-14.16v-7.08a7.08 7.08 0 0114.16 0v7.08c0 7.82 6.34 14.16 14.16 14.16zM267.57 188.67c7.82 0 14.16-6.34 14.16-14.16v-7.08c0-19.55-15.85-35.4-35.4-35.4s-35.4 15.85-35.4 35.4v7.08c0 7.82 6.34 14.16 14.16 14.16s14.16-6.34 14.16-14.16v-7.08a7.08 7.08 0 0114.16 0v7.08c0 7.82 6.34 14.16 14.16 14.16z\"/><path class=\"cls-2\" d=\"M247.75 202.83H145.8c-10.95 0-19.82 8.88-19.82 19.82v8.5c0 39.1 31.7 70.8 70.8 70.8s70.8-31.7 70.8-70.8v-8.5c0-10.95-8.88-19.82-19.82-19.82zm-8.5 28.32c0 23.46-19.02 42.48-42.48 42.48s-42.48-19.02-42.48-42.48h84.96z\"/><path class=\"cls-2\" d=\"M55.17 202.83c0 78.2 63.4 141.6 141.6 141.6s141.6-63.4 141.6-141.6-63.4-141.6-141.6-141.6-141.6 63.4-141.6 141.6zm254.88 0c0 62.56-50.72 113.28-113.28 113.28S83.49 265.39 83.49 202.83 134.21 89.55 196.77 89.55s113.28 50.72 113.28 113.28z\"/>",
    "attrs": {
      "id": "Layer_1",
      "data-name": "Layer 1",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 400 400"
    }
  };
  _exports.default = _default;
});