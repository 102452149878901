define("@cloudcreativity/ember-quill/services/quill", ["exports", "@ember/service", "quill", "@ember/debug"], function (_exports, _service, _quill, _debug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class QuillService extends _service.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "instances", {});
    }

    /**
     * Register a Quill instance by name.
     */
    register(name, instance) {
      (true && !(false === Boolean(this.instances[name])) && (0, _debug.assert)('Quill instance must not already be registered.', false === Boolean(this.instances[name])));
      this.instances[name] = instance;
    }
    /**
     * De-register a Quill instance by name.
     *
     * @returns {void}
     */


    deregister(name) {
      delete this.instances[name];
    }
    /**
     * Get a Quill instance by name.
     *
     * @returns {(Quill|null)}
     */


    instance(name) {
      return this.instances[name] ?? null;
    }
    /**
     * Delete text from the named editor.
     *
     * @param {string} name the named editor.
     * @param {number} index
     * @param {number} length
     * @param {string} source
     * @returns {(Delta|null)}
     */


    deleteText(name, index, length) {
      let source = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : _quill.default.sources.API;

      if (this.instances[name]) {
        return this.instances[name].deleteText(index, length, source);
      }

      return null;
    }
    /**
     * Retrieve the contents of the named editor.
     *
     * @param {string} name the named editor.
     * @returns {(Delta|null)}
     */


    getContents(name) {
      if (this.instances[name]) {
        return this.instances[name].getContents();
      }

      return null;
    }
    /**
     * Retrieve the length of the editor contents.
     *
     * @param {string} name the named editor.
     * @returns {(number|null)}
     */


    getLength(name) {
      if (this.instances[name]) {
        return this.instances[name].getLength();
      }

      return null;
    }
    /**
     * Retrieve the string content of the editor.
     *
     * @param {string} name the named editor.
     * @param {number} index
     * @param {number} length
     * @returns {(string|null)}
     */


    getText(name) {
      let index = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
      let length = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : undefined;

      if (this.instances[name]) {
        return this.instances[name].getText(index, length);
      }

      return null;
    }
    /**
     * Insert embedded content into the named editor.
     *
     * @param {string} name the named editor.
     * @param {number} index
     * @param {string} type
     * @param value
     * @param {string} source
     * @returns {(Delta|null)}
     */


    insertEmbed(name, index, type, value) {
      let source = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : _quill.default.sources.API;

      if (this.instances[name]) {
        return this.instances[name].insertEmbed(index, type, value, source);
      }

      return null;
    }
    /**
     * Insert text into the named editor.
     *
     * @param {string} name the named editor.
     * @param args
     * @returns {(Delta|null)}
     */


    insertText(name) {
      if (this.instances[name]) {
        for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
          args[_key - 1] = arguments[_key];
        }

        return this.instances[name].insertText(...args);
      }

      return null;
    }
    /**
     * Set contents on the named editor.
     *
     * @param {string} name the named editor.
     * @param {Delta} delta
     * @returns {(Delta|null)}
     */


    setContents(name, delta) {
      let source = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : _quill.default.sources.API;

      if (this.instances[name]) {
        return this.instances[name].setContents(delta, source);
      }

      return null;
    }
    /**
     * Set text contents on the named editor.
     *
     * @param {string} name the named editor.
     * @param {string} text
     * @param {string} source
     * @returns {(Delta|null)}
     */


    setText(name, text) {
      let source = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : _quill.default.sources.API;

      if (this.instances[name]) {
        return this.instances[name].setText(text, source);
      }

      return null;
    }
    /**
     * Apply delta to the named editor's contents.
     *
     * @param {string} name the named editor.
     * @param {Delta} delta
     * @param {string} source
     * @returns {(Delta|null)}
     */


    updateContents(name, delta) {
      let source = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : _quill.default.sources.API;

      if (this.instances[name]) {
        return this.instances[name].updateContents(delta, source);
      }

      return null;
    }
    /**
     * Format text at the user's current selection in the named editor.
     *
     * @param {string} name the editor name
     * @param {string} formatName
     * @param value
     * @param {string} source
     * @returns {(Delta|null)}
     */


    format(name, formatName, value) {
      let source = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : _quill.default.sources.API;

      if (this.instances[name]) {
        return this.instances[name].format(formatName, value, source);
      }

      return null;
    }
    /**
     * Formats all lines in a given range in the named editor.
     *
     * @param {string} name the named editor.
     * @param args
     * @returns {(Delta|null)}
     */


    formatLine(name) {
      if (this.instances[name]) {
        for (var _len2 = arguments.length, args = new Array(_len2 > 1 ? _len2 - 1 : 0), _key2 = 1; _key2 < _len2; _key2++) {
          args[_key2 - 1] = arguments[_key2];
        }

        return this.instances[name].formatLine(...args);
      }

      return null;
    }
    /**
     * Format text in the named editor.
     *
     * @param {string} name the named editor.
     * @param args
     * @returns {(Delta|null)}
     */


    formatText(name) {
      if (this.instances[name]) {
        for (var _len3 = arguments.length, args = new Array(_len3 > 1 ? _len3 - 1 : 0), _key3 = 1; _key3 < _len3; _key3++) {
          args[_key3 - 1] = arguments[_key3];
        }

        return this.instances[name].formatText(...args);
      }

      return null;
    }
    /**
     * Retrieves common formating of the text in the named editor.
     *
     * @param {string} name the named editor.
     * @param args
     * @returns {(String[]|null)}
     */


    getFormat(name) {
      if (this.instances[name]) {
        for (var _len4 = arguments.length, args = new Array(_len4 > 1 ? _len4 - 1 : 0), _key4 = 1; _key4 < _len4; _key4++) {
          args[_key4 - 1] = arguments[_key4];
        }

        return this.instances[name].getFormat(...args);
      }

      return null;
    }
    /**
     * Removes all formating and embeds within the given range, in the named editor.
     *
     * @param {string} name the editor name.
     * @param {number} index
     * @param {number} length
     * @param {string} source
     * @returns {(Delta|null)}
     */


    removeFormat(name, index, length) {
      let source = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : _quill.default.sources.API;

      if (this.instances[name]) {
        return this.instances[name].removeFormat(index, length, source);
      }

      return null;
    }
    /**
     * Retrieves the pixel position and dimensions of a selection in the named editor.
     *
     * @param {string} name the named editor.
     * @param {number} index
     * @param {number} length
     * @returns {(Object|null)}
     */


    getBounds(name, index) {
      let length = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;

      if (this.instances[name]) {
        return this.instances[name].getBounds(index, length);
      }

      return null;
    }
    /**
     * Retrieves the user's selection range in the named editor.
     *
     * @param {string} name the named editor.
     * @param {boolean} focus
     * @returns {(Object|null)}
     */


    getSelection(name) {
      let focus = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

      if (this.instances[name]) {
        return this.instances[name].getSelection(name, focus);
      }

      return null;
    }
    /**
     * Remove focus from the named editor.
     *
     * @param {string} name the named editor.
     */


    blur(name) {
      if (this.instances[name]) {
        this.instances[name].blur();
      }
    }
    /**
     * Disable from the named editor.
     *
     * @param {string} name the named editor.
     */


    disable(name) {
      if (this.instances[name]) {
        this.instances[name].disable();
      }
    }
    /**
     * Enable the named editor.
     *
     * @param {string} name the named editor.
     */


    enable(name) {
      if (this.instances[name]) {
        this.instances[name].enable();
      }
    }
    /**
     * Focuses the named editor.
     *
     * @param {string} name the named editor.
     */


    focus(name) {
      if (this.instances[name]) {
        this.instances[name].focus();
      }
    }
    /**
     * Does the named editor have focus?
     *
     * @return {(boolean|null)}
     */


    hasFocus(name) {
      if (this.instances[name]) {
        return this.instances[name].hasFocus();
      }

      return null;
    }
    /**
     * Synchronously check the named editor for user updates.
     *
     * @param {string} name the named editor
     * @param {string} source
     */


    update(name) {
      let source = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : _quill.default.sources.USER;

      if (this.instances[name]) {
        this.instances[name].update(source);
      }
    }

  }

  _exports.default = QuillService;
});