define("ember-svg-jar/inlined/icons/app/app.festival", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M7.6 6.8a1.8 1.8 0 10-3.6 0 1.8 1.8 0 003.6 0zm0 6a1.8 1.8 0 10-3.6 0 1.8 1.8 0 003.6 0zm0 5.4a1.8 1.8 0 10-3.6 0 1.8 1.8 0 003.6 0zm0 6a1.8 1.8 0 10-3.6 0 1.8 1.8 0 003.6 0z\"/><path fill-opacity=\".5\" d=\"M9.04 7.52V6.08c0-.398.364-.72.813-.72h16.374c.449 0 .813.322.813.72v1.44c0 .398-.364.72-.813.72H9.853c-.449 0-.813-.322-.813-.72zm0 5.808v-1.44c0-.398.36-.72.805-.72h14.59c.445 0 .805.322.805.72v1.44c0 .398-.36.72-.805.72H9.845c-.445 0-.805-.322-.805-.72zm0 11.568v-1.44c0-.398.366-.72.818-.72h11.564c.452 0 .818.322.818.72v1.44c0 .398-.366.72-.818.72H9.858c-.452 0-.818-.322-.818-.72zm0-5.808v-1.44c0-.398.37-.72.827-.72h13.346c.457 0 .827.322.827.72v1.44c0 .398-.37.72-.827.72H9.867c-.457 0-.827-.322-.827-.72z\"/>",
    "attrs": {
      "viewBox": "0 0 32 32",
      "fill": "currentColor"
    }
  };
  _exports.default = _default;
});