define("ember-svg-jar/inlined/sesh/app.sesh", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<circle cx=\"158.61\" cy=\"162.61\" r=\"156.35\" fill=\"currentColor\"/><path d=\"M1032.15 207.61H443.1c-24.85 0-45-20.15-45-45s20.15-45 45-45h589.05c24.85 0 45 20.15 45 45s-20.15 45-45 45zm-873.54 865.72c-86.21 0-156.35-70.14-156.35-156.35S72.4 760.63 158.61 760.63s156.35 70.14 156.35 156.35-70.14 156.35-156.35 156.35zm0-222.7c-36.58 0-66.35 29.76-66.35 66.35s29.76 66.35 66.35 66.35c36.58 0 66.35-29.76 66.35-66.35s-29.76-66.35-66.35-66.35zm873.54 111.35H443.1c-24.85 0-45-20.15-45-45s20.15-45 45-45h589.05c24.85 0 45 20.15 45 45s-20.15 45-45 45zM158.61 696.35C72.4 696.35 2.26 626.21 2.26 540S72.4 383.65 158.61 383.65 314.96 453.79 314.96 540s-70.14 156.35-156.35 156.35zm0-222.7c-36.58 0-66.35 29.76-66.35 66.35s29.76 66.35 66.35 66.35c36.58 0 66.35-29.76 66.35-66.35s-29.76-66.35-66.35-66.35zM1032.15 585H443.1c-24.85 0-45-20.15-45-45s20.15-45 45-45h589.05c24.85 0 45 20.15 45 45s-20.15 45-45 45z\" fill=\"currentColor\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "data-name": "Layer 1",
      "viewBox": "0 0 1080 1080"
    }
  };
  _exports.default = _default;
});