define("ember-svg-jar/inlined/icons/app/app.professional", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M59.5 3.9C28.1 3.9 2.7 29.3 2.7 60.7s25.4 56.8 56.8 56.8 56.8-25.4 56.8-56.8S90.9 3.9 59.5 3.9zm0 109.6c-29.2 0-52.8-23.6-52.8-52.8S30.3 7.9 59.5 7.9s52.8 23.6 52.8 52.8c0 29.1-23.6 52.8-52.8 52.8z\" class=\"st0\"/><path d=\"M23.3 103.9c-1.1 0-2-.9-2-2V31.8c0-1.1.9-2 2-2h72.4c1.1 0 2 .9 2 2v68.8c0 1.1-.9 2-2 2s-2-.9-2-2V33.8H25.3v68.1c0 1.1-.9 2-2 2z\" class=\"st0\"/><path d=\"M59.5 44.4c-7 0-12.6-5.7-12.6-12.6s5.7-12.6 12.6-12.6 12.6 5.7 12.6 12.6-5.7 12.6-12.6 12.6zm0-21.2c-4.7 0-8.6 3.9-8.6 8.6s3.9 8.6 8.6 8.6c4.7 0 8.6-3.9 8.6-8.6s-3.9-8.6-8.6-8.6z\" class=\"st0\"/><path d=\"M79.8 51.1H39.2c-1.1 0-2-.9-2-2V32.9c0-1.1.9-2 2-2s2 .9 2 2v14.2h36.6v-14c0-1.1.9-2 2-2s2 .9 2 2v16c0 1.1-.9 2-2 2z\" class=\"st0\"/><path d=\"M45.7 74.8H34c-1.1 0-2-.9-2-2V61.1c0-1.1.9-2 2-2h11.7c1.1 0 2 .9 2 2v11.7c0 1.1-.9 2-2 2zm-9.7-4h7.7v-7.7H36v7.7zm9.7 25.7H34c-1.1 0-2-.9-2-2V82.8c0-1.1.9-2 2-2h11.7c1.1 0 2 .9 2 2v11.7c0 1.1-.9 2-2 2zm-9.7-4h7.7v-7.7H36v7.7z\" class=\"st1\"/><path d=\"M81 66.1H52.8c-1.1 0-2-.9-2-2s.9-2 2-2H81c1.1 0 2 .9 2 2s-.9 2-2 2zm-11.1 6.3H52.8c-1.1 0-2-.9-2-2s.9-2 2-2h17.1c1.1 0 2 .9 2 2s-.9 2-2 2zM81 88H52.8c-1.1 0-2-.9-2-2s.9-2 2-2H81c1.1 0 2 .9 2 2s-.9 2-2 2zm-5.4 6.3H52.8c-1.1 0-2-.9-2-2s.9-2 2-2h22.8c1.1 0 2 .9 2 2s-.9 2-2 2z\" class=\"st0\"/><path d=\"M99.7 17.7l6.4 1.8L84.3 97l-4.4 2.5-2.3-4c0-.1 22.7-78.2 22.1-77.8z\"/><path d=\"M79.9 101.5c-.2 0-.4 0-.5-.1-.5-.1-.9-.5-1.2-.9l-2.3-4c-.3-.5-.3-1-.2-1.5 8.5-29.3 21.2-73.6 21.9-77.3 0-.7.4-1.3 1-1.7.5-.3 1.1-.3 1.6-.2l6.4 1.8c.5.1.9.5 1.2.9s.3 1 .2 1.5L86.2 97.6c-.1.5-.5.9-.9 1.2l-4.4 2.5c-.3.1-.7.2-1 .2zm-.1-6.3l.9 1.6 1.9-1.1 21-74.8-2.5-.7c-.5 2-1.4 5.2-2.8 10.3C96.5 37 94 46 90.7 57.2c-4.9 17.1-9.8 34.1-10.9 38z\" class=\"st0\"/>",
    "attrs": {
      "viewBox": "0 0 120 120",
      "fill": "currentColor"
    }
  };
  _exports.default = _default;
});