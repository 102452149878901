define("ember-svg-jar/inlined/icon.alert", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<defs><path id=\"a\" d=\"M0 0h79.999v80H0z\"/></defs><g fill=\"none\" fill-rule=\"evenodd\" transform=\"translate(0 .75)\"><mask id=\"b\" fill=\"#fff\"><use xlink:href=\"#a\"/></mask><path fill=\"#E41915\" d=\"M40 77.185C19.496 77.185 2.815 60.505 2.815 40 2.815 19.497 19.495 2.815 40 2.815c20.503 0 37.184 16.682 37.184 37.185 0 20.504-16.68 37.185-37.184 37.185M40 0C17.944 0 0 17.944 0 40s17.944 40 40 40c22.055 0 40-17.944 40-40S62.054 0 40 0\" mask=\"url(#b)\"/><path fill=\"#EA9DA7\" d=\"M39.974 65.277c-.94 0-1.831-.316-2.324-.825-.224-.23-.333-.47-.326-.72l.748-30.92c0-.527.781-1.114 1.902-1.114 1.149 0 2.008.587 2.008 1.145l.693 30.865c-.012.745-1.118 1.569-2.701 1.569m0-36.395c-2.646 0-4.717 1.726-4.716 3.895l-.748 30.874c-.032 1.002.366 1.983 1.118 2.76 1.02 1.052 2.644 1.681 4.346 1.681 3.094 0 5.516-1.93 5.516-4.395l-.693-30.885c0-2.204-2.119-3.93-4.823-3.93m.05-6.897c-2.03 0-3.68-1.629-3.68-3.63 0-2.002 1.65-3.631 3.68-3.631a3.634 3.634 0 013.63 3.63 3.634 3.634 0 01-3.63 3.63m0-10.075c-3.582 0-6.495 2.89-6.495 6.445s2.913 6.446 6.495 6.446a6.453 6.453 0 006.446-6.446 6.453 6.453 0 00-6.446-6.445\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink",
      "width": "80",
      "height": "81",
      "viewBox": "0 0 80 81"
    }
  };
  _exports.default = _default;
});